<template>
  <div class="home">
    <page-content>
      <ntm-block>
        <div class="d-flex justify-content-between mb-3 align-items-center">
          <div>
            <div class="btn-group mr-3">
              <button class="btn" :class="{'btn-info': view === 'calendar'}" @click="setView('calendar')">{{ $t('game.calendar') }}</button>
              <button class="btn" :class="{'btn-info': view === 'list'}" @click="setView('list')">{{ $t('list') }}</button>
            </div>
          </div>
          <div>
            <pdf-schedule-download class="mr-2"></pdf-schedule-download>
            <export-scheduled-games-csv></export-scheduled-games-csv>

            <div v-if="timeline?.gamesCount > 0" class="btn-group ml-3">
              <button class="btn btn-sm " :class="{'btn-info': currentTimeline === 'all'}" @click="setCurrentTimeline('all')">{{ $t('all') }}</button>
              <button class="btn btn-sm" :class="{'btn-info': currentTimeline === 'published'}" @click="setCurrentTimeline('published')">{{ $t('published') }}</button>
              <button class="btn btn-sm" :class="{'btn-info': currentTimeline === 'unpublished'}" @click="setCurrentTimeline('unpublished')">{{ $t('unpublished') }}</button>
            </div>
            <button v-if="timeline?.gamesCount > 0" @click="publishTimeline()"
                    class="btn btn-sm btn-success ml-3">
              {{ $t('schedule.publish') }}
              <span v-if="timeline?.gamesCount" class="ml-1 badge badge-pill badge-light">
              {{ timeline?.gamesCount }}
            </span>
            </button>
          </div>
        </div>

      </ntm-block>
      <le-schedule-calendar v-if="view === 'calendar'"></le-schedule-calendar>
      <le-schedule-list v-if="view === 'list'"></le-schedule-list>
    </page-content>
  </div>
</template>

<script>
import PdfScheduleDownload from '../components/shared/PdfScheduleDownload'
import { useTimeline } from '@/use/useTimeline'
import { useRouteQuery } from '@/use/useRouteQuery'
import ExportScheduledGamesCsv from '@/components/le/ExportScheduledGamesCsv.vue'
import LeScheduleCalendar from '@/components/le/LeScheduleCalendar.vue'
import LeScheduleList from '@/components/le/LeScheduleList.vue'

export default {
  components: {
    LeScheduleList,
    LeScheduleCalendar,
    ExportScheduledGamesCsv,
    PdfScheduleDownload
  },
  computed: {
    leagues () {
      return this.model.leagues ?? []
    }
  },
  data () {
    return {
      view: localStorage.getItem('le-schedule-view') || 'list'
    }
  },
  setup () {
    const currentTimeline = useRouteQuery('timeline', 'all')

    const { timeline, fetchCurrentTimeline, publishTimeline } = useTimeline()

    return {
      timeline,
      fetchCurrentTimeline,
      publishTimeline,
      currentTimeline
    }
  },
  methods: {
    setView (view) {
      this.view = view
      localStorage.setItem('le-schedule-view', view)
    },
    setCurrentTimeline (timeline) {
      this.currentTimeline = timeline
    }
  }
}
</script>
