<template>
  <div
    class="fc-event-main"
    :style="{ color: info.event.textColor, backgroundColor: info.event.backgroundColor }"
    ref="eventElement"
    @mouseenter="showTooltip = true"
    @mouseleave="showTooltip = false"
  >
    <div class="fc-event-main-frame">
      <div class="fc-event-time">{{ info.timeText }}</div>
      <div class="fc-event-title-container">
        <div class="fc-event-title fc-sticky">{{ info.event.title }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import tippy from 'tippy.js' // Import Tippy.js

export default {
  props: {
    info: Object,
    getRoundName: Function
  },
  data () {
    return {
      showTooltip: false,
      tooltipInstance: null
    }
  },
  mounted () {
    // Select all elements with the 'data-tippy-root' attribute
    const elements = document.querySelectorAll('[data-tippy-root]')

    elements.forEach(element => {
      element.remove()
    })

    this.tooltipInstance = tippy(this.$refs.eventElement, {
      content: this.createTooltipContent(),
      allowHTML: true,
      theme: 'light',
      interactive: true,
      trigger: 'manual',
      placement: 'right',
      animation: false,
      duration: [0, 100000],
      appendTo: () => document.body,
      onShow: () => {
        this.showTooltip = true // Show tooltip on trigger
      },
      onHide: () => {
        this.showTooltip = false // Hide tooltip on trigger
      }
    })
  },
  watch: {
    showTooltip (newVal) {
      if (newVal) {
        this.tooltipInstance.show() // Show tooltip if showTooltip is true
      } else {
        this.tooltipInstance.hide() // Hide tooltip if showTooltip is false
      }
    }
  },
  methods: {
    createTooltipContent () {
      const event = this.info.event

      return `
        <div class="event-tooltip-content" style="background-color: ${event.backgroundColor}">
          <div class="text-center px-2 py-1 font-weight-bold"><span class="bg-white text-black-100 font-weight-bold px-1 rounded">${this.info.timeText}</span></div>
          <div class="d-flex flex-column js-event-teams">

            <div class="px-2 py-1 bg-white font-weight-bold text-black-100 d-flex justify-content-between align-items-center font-size-sm"><span>${event.extendedProps.teamA?.name ?? 'TBD'}</span></div>
            <div class="px-2 py-1 bg-white font-weight-bold text-black-100 d-flex justify-content-between align-items-center font-size-sm"><span>${event.extendedProps.teamB?.name ?? 'TBD'}</span></div>
          </div>
          <div class="px-2 py-1 font-weight-bold d-flex justify-content-between" style="color: ${event.textColor}"><span class="mr-2">${event.extendedProps.league.name}</span> <span>${this.getRoundName(event.extendedProps.round, event.extendedProps.playoffRound)}</span></div>
       </div>
      `
    }
  },
  destroyed () {
    console.log('Destroying tooltip instance')
    // Clean up tooltip instance when component is destroyed
    if (this.tooltipInstance) {
      this.tooltipInstance.destroy()
    }
  }
}
</script>

<style>
.event-tooltip-content {
  min-width: 130px;
  border-radius: 5px;
  font-size: 12px;
}
</style>
