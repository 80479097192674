const fields = [
  {
    key: 'venue',
    type: 'ntm-select',
    optionsApi: 'venues',
    templateOptions: {
      label: 'venue'
    }
  },
  {
    key: 'time',
    type: 'datepicker',
    format: 'YYYY-MM-DD h:mm a',
    templateOptions: {
      label: 'time'
    }
  },
  {
    key: 'info',
    type: 'textarea',
    templateOptions: {
      label: 'info'
    }
  }
]

export default fields
