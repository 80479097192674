<template>
  <div id="page-container"
       :class="{'sidebar-o': $store.state.isNavOpened, 'sidebar-o-xs': $store.state.isNavOpened, 'sidebar-mini': $store.state.isMiniNavOpened, 'side-overlay-o': activitySidebar}"
       v-if="config"
       class="sidebar-o sidebar-dark side-scroll page-header-fixed">
    <!-- Sidebar -->
    <!--
        Sidebar Mini Mode - Display Helper classes

        Adding 'smini-hide' class to an element will make it invisible (opacity: 0) when the sidebar is in mini mode
        Adding 'smini-show' class to an element will make it visible (opacity: 1) when the sidebar is in mini mode
            If you would like to disable the transition animation, make sure to also add the 'no-transition' class to your element

        Adding 'smini-hidden' to an element will hide it when the sidebar is in mini mode
        Adding 'smini-visible' to an element will show it (display: inline-block) only when the sidebar is in mini mode
        Adding 'smini-visible-block' to an element will show it (display: block) only when the sidebar is in mini mode
    -->
    <navigation></navigation>
    <!-- END Sidebar -->

    <!-- Header -->
    <topbar></topbar>
    <!-- END Header -->
    <ntm-sidebar :title="$tc('activity.name', 2)" name="activities" @opened="fetchActivities(true)" @loadMore="fetchActivities()">
      <template v-slot:options>
        <router-link class="btn btn-sm btn-info ml-2" :to="{name: 'activities.index'}">{{$tc('filter', 2)}}</router-link>
      </template>
    <activity-log v-for="(activity, a) in activities.data" :activity="activity" :key="a"></activity-log>
    </ntm-sidebar>
    <!-- Main Container -->
    <main id="main-container">
      <transition name="fade">
        <loader v-if="this.$store.state.loading"></loader>
      </transition>
      <div v-if="swapGame?.uuid" class="position-fixed bg-white p-2 rounded shadow" style="z-index: 2; right:15px; top: 75px">
        <p class="m-0 mb-2 d-flex justify-content-between align-items-center"><span class="font-weight-bold mr-3">{{ $t('game.swapTimeAndVenue') }}</span> <button @click="swapGame = null" class="btn btn-sm btn-danger"><i class="fa fa-close"></i></button></p>
        <hr class="mx-0 my-1">
        <p class="m-0 font-weight-bold"><strong>{{ swapGame.teamA?.name ?? '?' }}</strong> :
          <strong>{{ swapGame.teamB?.name ?? '?' }}</strong></p>
        <p class="m-0 mb-2">{{ getSwapGameLeagueInfo }}</p>
        <p v-if="swapGame.venue" class="m-0 font-weight-bold">{{swapGame.venue.name}}</p>
        <p v-if="swapGame.time" class="m-0 ">{{$d(new Date(swapGame.time.replace('T', ' ').replace(/-/g, '/')), 'dateLong')}}</p>
        <p v-if="swapGame.time" class="m-0 font-weight-bold">{{$d(new Date(swapGame.time.replace('T', ' ').replace(/-/g, '/')), 'time') }}</p>
      </div>
      <router-view :key="currentClient.uuid"></router-view>
    </main>

    <modal name="finished-games" height="auto" :minWidth="400" width="80%">
      <div class="content">
        <h3 class="text-center">Finished games</h3>
        <div class="body">
          <table class="table table-bordered table-striped table-vcenter">
            <thead>
            <tr>
              <th>Time</th>
              <th>League</th>
              <th class="text-center">Game</th>
              <th class="text-center">Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="game in finishedGames" :key="game.uuid">
              <td>{{ $d(new Date(game.time), 'short') }}</td>
              <td>{{ game.league.name }}</td>
              <td class="text-center"><strong>{{ game.teamA.name }}</strong> :
                <strong>{{ game.teamB.name }}</strong></td>
              <td class="text-center">
                <div class="btn-group">
                  <button @click="goToGame(game)" class="btn btn-sm btn-info"><i class="fa fa-pencil"></i></button>
                  <button @click="archiveGame(game)" class="btn btn-sm btn-success">{{$t('archive.action')}}</button>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </modal>

    <!-- END Main Container -->
    <bottombar></bottombar>
  </div>

</template>

<script>
import Topbar from '@/components/layout/Topbar'
import Navigation from '@/components/layout/Navigation'
import Bottombar from '@/components/layout/Bottombar'
import Loader from '@/components/layout/Loader'
import App from '../oneui/app'
import GameService from '@/services/game.service'
import { useRouteQuery } from '@/use/useRouteQuery'
import ActivityService from '@/services/activity.service'
import ActivityLog from '@/components/shared/ActivityLog.vue'
import { TokenService } from '@/services/storage.service'
import UserService from '@/services/user.service'
import store from '@/store'
import { useGameSwap } from '@/use/useGameSwap'
import ZendeskService from '@/services/zendesk.service'
// import FreshChatService from '@/services/freshChat.service'

export default {
  name: 'Default',
  components: {
    ActivityLog,
    Topbar,
    Navigation,
    Bottombar,
    Loader
  },
  setup () {
    const activitySidebar = useRouteQuery('activities')
    const { swapGame } = useGameSwap()

    return { activitySidebar, swapGame }
  },
  async beforeRouteEnter (to, from, next) {
    if (TokenService.getToken()) {
      await UserService.getUser().then((response) => {
        return store.commit('currentUser', { ...response.data, currentClientId: to.params?.clientId })
      })
    }

    next()
  },
  data () {
    return {
      activities: {
        data: [],
        meta: {
          current_page: 0,
          last_page: 1
        }
      }
    }
  },
  created () {
    // eslint-disable-next-line
    jQuery(() => {
      // Create a new instance of App
      window.One = new App()
    })
  },
  mounted () {
    if (process.env.VUE_APP_ZENDESK_KEY) {
      this.loadZendeskScript()
    }

    const user = this.$store.state.user

    this.$mixpanel.identify(user.uuid)

    this.$mixpanel.people.set({
      $name: user.name,
      $email: user.email
    })
  },
  methods: {
    loadZendeskScript () {
      // Check if the script is already loaded to avoid multiple inserts
      if (document.getElementById('ze-snippet')) return

      // Create a new script element
      const script = document.createElement('script')
      script.id = 'ze-snippet'
      script.src = 'https://static.zdassets.com/ekr/snippet.js?key=' + process.env.VUE_APP_ZENDESK_KEY

      // Append the script to the document body
      document.body.appendChild(script)

      // Optionally, you can add an event listener to check when the script has loaded
      script.onload = async () => {
        this.configureZendeskSDK()
      }
    },
    configureZendeskSDK () {
      window.zE('messenger', 'loginUser', async function (callback) {
        try {
          const response = await ZendeskService.getToken()

          if (response && response.data && response.data.token) {
            // Use the token to authenticate the user
            callback(response.data.token)
          }
        } catch (error) {
          console.error('Error fetching token:', error)
        }
      })
    },
    getPlayoffRound (playoffRound) {
      if (!playoffRound) {
        return
      }

      if (playoffRound > 8) {
        return this.$t('playoff.round.bestOf', { round: playoffRound })
      } else {
        return this.$t(`playoff.round.${playoffRound}`)
      }
    },
    fetchActivities (loadAgain = false) {
      if (loadAgain) {
        this.activities.data = []
        this.activities.meta.current_page = 0
      }

      const page = this.activities.meta.current_page + 1
      this.activities.meta.current_page = page
      ActivityService.index({ page }).then((response) => {
        this.activities.data.push(...response.data.data)
      })
    },
    archiveGame (game) {
      GameService.resolveAndArchive(game.schedule.uuid, game.uuid).then(() => {
        this.$store.dispatch('fetchFinishedGames')
        this.$store.commit('loaded')
      })
    },
    goToGame (game) {
      this.$modal.hide('finished-games')
      this.$router.push({ name: 'schedules.games.show', params: { uuid: game.schedule.uuid, game: game.uuid } })
    }
  },
  computed: {
    getSwapGameLeagueInfo () {
      let text = `${this.swapGame.league.name}`

      if (this.swapGame.playoffRound) {
        text += ` - ${this.getPlayoffRound(this.swapGame.playoffRound)} - ${this.$tc('game.name', 1)}: ${this.swapGame.round}`
      } else {
        text += ` - ${this.$tc('round', 1)}: ${this.swapGame.round}`
      }

      return text
    },
    currentClient () {
      return this.$store.state.currentClient
    },
    finishedGames () {
      return this.$store.state.finishedGames
    },
    config () {
      return this.$store.state.config
    }
  }
}
</script>

<style lang="scss">
  .fade-leave-active {
    transition-property: opacity;
    transition-duration: .1s;
  }

  .fade-enter, .fade-leave-active {
    opacity: 0
  }

  #main-container {
    position: relative;
  }
</style>
