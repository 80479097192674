<template>
  <div>
    <div class="row">
      <div class="col-md-8 mb-3">
        <div class="position-relative">
          <le-loading v-if="isCalendarLoading"></le-loading>
          <FullCalendar ref="fullCalendar" :options="calendarOptions" />
        </div>
      </div>
      <div class="col-md-4">
        <ntm-block class="mt-6 mb-3">
          <multiselect
            class="mb-2"
            :multiple="false"
            :placeholder="$tc('select.schedule', 1)"
            :custom-label="customSelectedVenueLabel"
            v-model="selectedVenue"
            :options="venues">

          </multiselect>
          <button class="btn btn-sm mb-2" :class="{'btn-success': filterByVenue}" @click="setFilterByVenue(!filterByVenue)">
            {{ $t('filterGamesByVenue') }}</button>
        </ntm-block>
        <div class="games-to-schedule d-flex justify-content-between bg-white align-items-baseline">
          <div class="games-to-schedule-leagues w-100 p-2 m-0">
            <multiselect
              :multiple="false"
              :placeholder="$tc('select.schedule', 1)"
              :custom-label="customSelectedVenueLabel"
              v-model="selectedSchedule"
              :options="schedules"></multiselect>
          </div>

          <div class="games-to-schedule-sort text-right p-2">
            <div class="btn-group">
              <button class="btn btn-sm" :class="{'btn-success': orderByNonPlayedGames}" @click="setOrderByNonPlayedGames(true)"><i v-if="orderByNonPlayedGames" class="fa fa-sort-amount-desc"></i> {{ $tc('game.status.NOT_PLAYED', 2) }}</button>
              <button class="btn btn-sm" :class="{'btn-success': !orderByNonPlayedGames}" @click="setOrderByNonPlayedGames(false)"><i v-if="!orderByNonPlayedGames" class="fa fa-sort-amount-asc"></i> {{ $tc('round', 1) }}</button>
            </div>
          </div>
        </div>

          <div class="position-relative p-3 overflow-y-auto games-to-schedule"
               style="height: 700px;">
            <le-loading v-if="isGamesToScheduleLoading"></le-loading>

            <ul id="js-events" class="list list-events">
              <li
                v-for="(event, index) in sortedGamesToSchedule"
                :key="`events-${index}`"
                :data-id="event.extendedProps.uuid"
                @click="openModalFromEvent(event)"
                class="mb-3 shadow"
              >
                <div
                  class="js-event rounded"
                  :style="{'background-color': event.extendedProps.backgroundColor, 'color': event.extendedProps.textColor}"
                >
                  <div class="d-flex align-items-stretch">
                    <div class="d-flex align-items-center rounded-left justify-content-center bg-white" style="width: 24px; flex: none;">
                      <drag-icon style="width: 10px; color: #808080" />
                    </div>
                    <div class="w-100">
                      <div class="d-flex flex-column js-event-teams">
                        <div class="p-1 bg-white text-black-50 js-event-home-team d-flex justify-content-between align-items-center rounded-0"><span><span :class="getTeamGamesCount(event.extendedProps.teamA?.uuid).scheduledGamesCount > 0 ? 'badge-success' : 'badge-warning'" class="badge mr-2">{{ getTeamGamesCount(event.extendedProps.teamA?.uuid).scheduledGamesCount }}</span>{{event.extendedProps.teamA?.name ?? 'TBD'}}</span> <span :class="getTeamNonPlayedGamesCountClass(event.extendedProps.teamA?.uuid)" class="badge">{{ getTeamGamesCount(event.extendedProps.teamA?.uuid).nonPlayedGamesCount }}</span></div>
                        <div class="p-1 bg-white text-black-50 js-event-away-team d-flex justify-content-between align-items-center"><span><span :class="getTeamGamesCount(event.extendedProps.teamB?.uuid).scheduledGamesCount > 0 ? 'badge-success' : 'badge-warning'" class="badge mr-2">{{ getTeamGamesCount(event.extendedProps.teamB?.uuid).scheduledGamesCount }}</span>{{event.extendedProps.teamB?.name ?? 'TBD'}}</span> <span :class="getTeamNonPlayedGamesCountClass(event.extendedProps.teamB?.uuid)" class="badge">{{ getTeamGamesCount(event.extendedProps.teamB?.uuid).nonPlayedGamesCount }}</span></div>
                      </div>
                      <div class="px-2 py-1">{{getRoundName(event.extendedProps.round, event.extendedProps.playoffRound)}}</div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
      </div>
    </div>
    <modal name="schedule-calendar-game" :adaptive="true" height="auto">
      <form @submit.prevent="scheduleGameFromForm">
        <ntm-block :title="selectedGame.title">
          <formly-form :form="scheduleGameForm" :model="selectedGame" :fields="scheduleGameFields"></formly-form>
          <div class="d-flex mb-2">
            <button type="submit" class="btn btn-success mr-2" :disabled="scheduleGameLoading"><span
              v-if="!scheduleGameLoading">{{ $t('save') }}</span><i v-if="scheduleGameLoading"
                                                                    class="fa fa-cog fa-spin"></i></button>
            <button v-if="selectedGame.status === 'SCHEDULED'" type="button" @click="cancelGameSchedule" class="btn btn-danger" :disabled="scheduleGameLoading"><span
              v-if="!scheduleGameLoading">{{ $t('cancel') }}</span><i v-if="scheduleGameLoading"
                                                                      class="fa fa-cog fa-spin"></i></button>
          </div>
        </ntm-block>
      </form>
    </modal>
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin, { Draggable } from '@fullcalendar/interaction'
import DashboardService from '@/services/dashboard.service'
import Multiselect from 'vue-multiselect'
import LeLoading from '@/components/ntm/LeLoading.vue'
import GameService from '@/services/game.service'
import scheduleGameFields from '@/views/schedules/games/formDefinitions/scheduleFormDefinition'
import { useTimeline } from '@/use/useTimeline'
import { useRouteQuery } from '@/use/useRouteQuery'
import { mapState } from 'vuex'
import Vue from 'vue'
import LeGameCalendarEvent from '@/components/le/LeGameCalendarEvent.vue'
import DragIcon from '@/components/svg/DragIcon.vue'
export default {
  components: { DragIcon, LeLoading, Multiselect, FullCalendar },
  data () {
    return {
      filterByVenue: localStorage.getItem('filter-by-venue') === 'true',
      orderByNonPlayedGames: localStorage.getItem('order-by-non-played-games') === 'true',
      gamesToScheduleTeams: [],
      scheduleGameFields: scheduleGameFields,
      scheduleGameLoading: false,
      scheduleGameForm: {},
      selectedGame: {
        title: '',
        status: null,
        schedule: null,
        uuid: '',
        time: '',
        venue: null,
        info: ''
      },
      isCalendarLoading: true,
      isGamesToScheduleLoading: false,
      schedules: [],
      selectedSchedule: null,
      selectedVenue: null,
      venues: [],
      calendarOptions: {
        firstDay: 1,
        plugins: [timeGridPlugin, interactionPlugin, dayGridPlugin],
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'timeGridWeek,timeGridDay' // ✅ Add month, week, and day views
        },
        locale: 'en',
        initialView: 'timeGridWeek',
        editable: true,
        selectable: true,
        slotMinTime: '06:00:00',
        slotMaxTime: '24:00:00',
        slotDuration: '00:30:00',
        snapDuration: '00:05:00',
        slotLabelInterval: '00:30:00',
        height: 'auto',
        slotLabelFormat: {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false
        },
        eventTimeFormat: {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false
        },
        buttonText: {
          today: 'Today',
          month: 'Month',
          week: 'Week',
          day: 'Day'
        },
        allDaySlot: false,
        events: this.fetchEvents,
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        eventDrop: this.handleEventDrop,
        datesSet: this.handleViewChange,
        eventReceive: this.handleExternalDrop,
        eventDidMount: this.eventDidMount,
        eventDragStart: this.handleEventStartDrag,
        eventDragStop: this.handleEventStopDrag,
        eventContent: this.renderEvent
      },
      gamesToSchedule: []
    }
  },
  setup () {
    const currentTimeline = useRouteQuery('timeline', 'all')
    const { fetchCurrentTimeline } = useTimeline()

    return {
      currentTimeline,
      fetchCurrentTimeline
    }
  },
  mounted () {
    this.calendarOptions.locale = this.language
    this.calendarOptions.slotLabelFormat.hour12 = this.settings.formatOfTime === '12h-clock'
    this.calendarOptions.eventTimeFormat.hour12 = this.settings.formatOfTime === '12h-clock'

    const container = document.getElementById('js-events')

    // eslint-disable-next-line no-new
    new Draggable(container, {
      itemSelector: '.js-event',
      eventData: (eventEl) => {
        const gameUuid = eventEl.parentElement.getAttribute('data-id')
        const eventInfo = this.gamesToSchedule.find(e => e.extendedProps.uuid === gameUuid)

        return {
          title: eventInfo.title,
          backgroundColor: eventInfo.extendedProps.backgroundColor,
          textColor: eventInfo.extendedProps.textColor,
          duration: eventInfo.duration,
          durationEditable: eventInfo.durationEditable,
          extendedProps: eventInfo.extendedProps
        }
      }
    })

    DashboardService.currentSeasonSchedules().then(response => {
      this.schedules = response.data
      this.selectedSchedule = this.schedules[0]
    })

    this.$mixpanel.start_session_recording()
  },
  beforeDestroy () {
    this.$mixpanel.stop_session_recording()
  },
  computed: {
    ...mapState(['language']),
    sortedGamesToSchedule () {
      const teamsMap = new Map(this.gamesToScheduleTeams.map(team => [team.uuid, team.nonPlayedGamesCount]))

      return [...this.gamesToSchedule].sort((a, b) => {
        if (a.extendedProps.playoffRound && !b.extendedProps.playoffRound) {
          return -1
        }

        if (!a.extendedProps.playoffRound && b.extendedProps.playoffRound) {
          return 1
        }

        if (a.extendedProps.playoffRound && b.extendedProps.playoffRound) {
          if (a.extendedProps.playoffRound !== b.extendedProps.playoffRound) {
            return b.extendedProps.playoffRound - a.extendedProps.playoffRound
          }
        }

        if (this.orderByNonPlayedGames) {
          // Calculate total non-played games for each game
          const aTotal = (teamsMap.get(a.extendedProps.teamA?.uuid) || 0) + (teamsMap.get(a.extendedProps.teamB?.uuid) || 0)
          const bTotal = (teamsMap.get(b.extendedProps.teamA?.uuid) || 0) + (teamsMap.get(b.extendedProps.teamB?.uuid) || 0)

          // First, sort by total non-played games (higher first)
          if (bTotal !== aTotal) {
            return bTotal - aTotal
          }
        }

        // If equal, sort by round number (lower first)
        return a.extendedProps.round - b.extendedProps.round
      })
    },
    teamNonPlayedGamesMin () {
      return Math.min(...this.gamesToScheduleTeams.map(team => team.nonPlayedGamesCount))
    },
    teamNonPlayedGamesAverage () {
      return Math.round(this.gamesToScheduleTeams.reduce((acc, team) => acc + team.nonPlayedGamesCount, 0) / this.gamesToScheduleTeams.length)
    },
    settings () {
      return this.$store.state.settings
    }
  },
  watch: {
    language (newLang) {
      // Assuming FullCalendar is initialized here
      this.$refs.fullCalendar.getApi().setOption('locale', newLang)
    },
    currentTimeline: 'refreshCalendar',
    selectedVenue () {
      this.refreshVenueAvailabilities()
      this.updateEventVisibility()
    },
    selectedSchedule: 'fetchGamesToSchedule'
  },
  methods: {
    renderEvent (arg) {
      if (arg.event.display === 'background') {
        return
      }

      const EventConstructor = Vue.extend(LeGameCalendarEvent)

      const eventInstance = new EventConstructor({
        propsData: {
          info: arg,
          getRoundName: this.getRoundName
        }
      }).$mount()

      return { domNodes: [eventInstance.$el] }
    },
    setFilterByVenue (value) {
      this.filterByVenue = value
      localStorage.setItem('filter-by-venue', value)
      this.updateEventVisibility()
    },
    eventDidMount (info) {
      this.showTooltip(info)
    },
    updateEventVisibility () {
      this.$nextTick(() => {
        const calendarApi = this.$refs.fullCalendar.getApi()
        calendarApi.getEvents().forEach(event => {
          if (event.display === 'background') {
            return
          }

          if (this.selectedVenue && this.filterByVenue && event.extendedProps.venue?.uuid !== this.selectedVenue.uuid) {
            event.setProp('display', 'none')
          } else {
            event.setProp('display', 'block')
          }
        })
      })
    },
    getCalendarDateRange () {
      const calendarApi = this.$refs.fullCalendar.getApi()
      const startDate = calendarApi.view.currentStart
      const endDate = calendarApi.view.currentEnd

      return {
        fromDate: startDate,
        toDate: endDate
      }
    },
    setOrderByNonPlayedGames (value) {
      this.orderByNonPlayedGames = value
      localStorage.setItem('order-by-non-played-games', value)
    },
    fetchGamesToSchedule () {
      if (this.selectedSchedule) {
        this.isGamesToScheduleLoading = true
        DashboardService.getGamesToSchedule(this.selectedSchedule.uuid, this.getCalendarDateRange()).then(response => {
          this.gamesToSchedule = response.data.games.map(game => this.mapGameToEvent(game))
          this.gamesToScheduleTeams = response.data.teams
        }).finally(() => {
          this.isGamesToScheduleLoading = false
        })
      }
    },
    refreshVenueAvailabilities () {
      const calendarApi = this.$refs.fullCalendar.getApi()

      // Remove previous venue availability events (which have `display: 'background'`)
      calendarApi.getEvents().forEach(event => {
        if (event.display === 'background') {
          event.remove()
        }
      })

      // Add new venue availability events
      if (this.selectedVenue) {
        this.selectedVenue.availabilities.forEach(availability => {
          calendarApi.addEvent(this.mapVenueAvailabilityToEvent(this.selectedVenue, availability))
        })
      }
    },
    customSelectedVenueLabel (o) {
      return `${o.name ? o.name : ''}`
    },
    refreshCalendar () {
      const calendarApi = this.$refs.fullCalendar.getApi()
      calendarApi.getEvents().forEach(event => event.remove())
      calendarApi.refetchEvents()
    },
    removeGameEvent (gameUuid) {
      const event = this.$refs.fullCalendar.getApi().getEvents().find(event => event.extendedProps.uuid === gameUuid)
      event.remove()
    },
    mapVenueAvailabilityToEvent (venue, availability) {
      return {
        title: venue.name,
        start: availability.fromDateTime,
        end: availability.toDateTime,
        durationEditable: false,
        editable: false,
        extendedProps: venue,
        display: 'background',
        backgroundColor: 'lightgreen'
      }
    },
    mapGameToEvent (game) {
      const editable = ['NOT_PLAYED', 'SCHEDULED'].includes(game.status)

      const duration = this.convertMinutesToDuration(game.gameDurationInMinutes)

      return {
        title: game.shortTitle,
        start: game.time,
        end: game.endTime,
        durationEditable: false,
        editable: editable,
        duration: duration,
        extendedProps: game,
        backgroundColor: game.backgroundColor,
        classNames: [this.getColorByStatus(game.status)],
        textColor: game.textColor
      }
    },
    getColorByStatus (status) {
      switch (status) {
        case 'SCHEDULED':
          return 'border-warning'
        case 'FINISHED':
        case 'ARCHIVED':
          return 'border-success'
        case 'LIVE':
          return 'border-danger'
        default:
          return 'secondary'
      }
    },
    getTeamGamesCount (teamUuid) {
      if (!teamUuid) {
        return {
          nonPlayedGamesCount: 0,
          scheduledGamesCount: 0
        }
      }

      const team = this.gamesToScheduleTeams.find(team => team.uuid === teamUuid)
      return {
        nonPlayedGamesCount: team.nonPlayedGamesCount,
        scheduledGamesCount: team.scheduledGamesCount
      }
    },
    getTeamNonPlayedGamesCountClass (teamUuid) {
      if (!teamUuid) return 'badge-success'

      const team = this.gamesToScheduleTeams.find(team => team.uuid === teamUuid)

      return {
        'badge-danger': team.nonPlayedGamesCount > this.teamNonPlayedGamesAverage,
        'badge-warning': team.nonPlayedGamesCount <= this.teamNonPlayedGamesAverage && team.nonPlayedGamesCount > this.teamNonPlayedGamesMin,
        'badge-success': team.nonPlayedGamesCount === this.teamNonPlayedGamesMin
      }
    },
    async fetchEvents (fetchInfo, successCallback, failureCallback) {
      this.isCalendarLoading = true
      try {
        const response = await DashboardService.getGamesCalendar(fetchInfo.startStr, fetchInfo.endStr, this.currentTimeline, null)
        const events = response.data.games.map(game => this.mapGameToEvent(game))
        this.venues = response.data.venues

        if (this.selectedVenue) {
          this.selectedVenue = this.venues.find(venue => venue.uuid === this.selectedVenue.uuid)
        } else {
          this.selectedVenue = this.venues[0]
        }

        successCallback(events) // Pass fetched events to the calendar
      } catch (error) {
        failureCallback(error)
      } finally {
        this.isCalendarLoading = false
      }
    },
    handleDateSelect (info) {
      // console.log('selectInfo', info)
    },
    handleEventClick (info) {
      this.openModalFromEvent(info.event)
    },
    openModalFromEvent (event) {
      if (event.extendedProps.status !== 'NOT_PLAYED' && event.extendedProps.status !== 'SCHEDULED') {
        return
      }

      this.selectedGame.title = event.extendedProps.title
      this.selectedGame.schedule = event.extendedProps.schedule
      this.selectedGame.uuid = event.extendedProps.uuid
      this.selectedGame.time = event.extendedProps.time
      this.selectedGame.venue = event.extendedProps.venue
      this.selectedGame.info = event.extendedProps.info
      this.selectedGame.status = event.extendedProps.status
      this.$modal.show('schedule-calendar-game')
    },
    handleExternalDrop (info) {
      const gameUuid = info.event.extendedProps.uuid // Get unique event ID

      this.scheduleGameFromEvent(info.event, true)

      // Remove the event from calendarNewEvents
      this.gamesToSchedule = this.gamesToSchedule.filter(event => event.extendedProps.uuid !== gameUuid)

      this.$nextTick(() => {
        this.showTooltip(info)
      })

      this.$mixpanel.track('Schedule game on calendar')
    },
    handleEventDrop (info) {
      this.scheduleGameFromEvent(info.event, false, false)
      this.$mixpanel.track('Update scheduled game on calendar')
    },
    scheduleGameFromEvent (event, takeVenueFromSelect = true, fetchGamesToSchedule = true) {
      if (fetchGamesToSchedule) {
        this.isGamesToScheduleLoading = true
      }

      GameService.scheduleGame(event.extendedProps.schedule.uuid, event.extendedProps.uuid, {
        time: this.formatDate(event.start),
        venue: takeVenueFromSelect ? this.selectedVenue.uuid : event.extendedProps.venue.uuid
      }).then((res) => {
        event.setExtendedProp('title', event.extendedProps.title)
        event.setExtendedProp('status', res.data.status)
        event.setExtendedProp('venue', res.data.venue)
        event.setExtendedProp('time', res.data.time)
        event.setProp('classNames', this.getColorByStatus(res.data.status))

        if (fetchGamesToSchedule) {
          this.fetchGamesToSchedule()
        }

        this.fetchCurrentTimeline()
      }).catch((err) => {
        console.error('Error scheduling game:', err)
      })
    },
    handleEventStartDrag (info) {
      //
    },
    handleEventStopDrag (info) {
      //
    },
    async handleViewChange (view) {
      this.fetchGamesToSchedule()
    },
    formatDate (date) {
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0') // Dodaj nulu ako je potrebno
      const day = String(date.getDate()).padStart(2, '0')
      const hours = String(date.getHours()).padStart(2, '0')
      const minutes = String(date.getMinutes()).padStart(2, '0')

      return `${year}-${month}-${day} ${hours}:${minutes}`
    },
    scheduleGameFromForm () {
      this.isGamesToScheduleLoading = true
      this.scheduleGameLoading = true

      GameService.scheduleGame(this.selectedGame.schedule.uuid, this.selectedGame.uuid, {
        time: this.selectedGame.time,
        venue: this.selectedGame.venue,
        info: this.selectedGame.info
      }).then((res) => {
        this.$modal.hide('schedule-calendar-game')
        this.refreshCalendar()
        this.fetchGamesToSchedule()
        this.fetchCurrentTimeline()
      }).catch((err) => {
        this.scheduleGameForm.$errors = err.response.data.errors
      }).finally(() => {
        this.scheduleGameLoading = false
        this.$mixpanel.track('Schedule game on calendar using form')
      })
    },
    cancelGameSchedule () {
      this.scheduleGameLoading = true
      GameService.cancelScheduledGame(this.selectedGame.schedule.uuid, this.selectedGame.uuid).then((res) => {
        this.$modal.hide('schedule-calendar-game')
        this.removeGameEvent(this.selectedGame.uuid)
        this.fetchGamesToSchedule()
        this.fetchCurrentTimeline()
      }).finally(() => {
        this.scheduleGameLoading = false
        this.$mixpanel.track('Cancel scheduled game on calendar')
      })
    },
    getRoundName (round, playoffRound = null) {
      if (!playoffRound) {
        return `${this.$tc('round', 1)} ${round}`
      }

      if (playoffRound > 8) {
        return `${this.$t('playoff.round.bestOf', { round: playoffRound })}, ${this.$tc('game.name', 1)} ${round}`
      } else {
        return `${this.$t(`playoff.round.${playoffRound}`)}, ${this.$tc('game.name', 1)} ${round}`
      }
    },
    showTooltip (info) {
      //
    },
    convertMinutesToDuration (minutes) {
      const hours = Math.floor(minutes / 60).toString().padStart(2, '0')
      const mins = (minutes % 60).toString().padStart(2, '0')
      return `${hours}:${mins}:00` // Ensuring correct format HH:mm:ss
    }
  }
}
</script>
<style lang="scss">
  @media (max-width: 1600px) {
    .games-to-schedule {
      flex-direction: column; /* Stack items vertically on smaller screens */
    }
  }
</style>
