<template>
  <div v-if="model.schedule">
    <page-header :title="$t('game.edit')">
      <li class="breadcrumb-item">
        <router-link :to="{name:'schedules.index'}">{{ $tc('schedule.name', 2) }}</router-link>
      </li>
      <li class="breadcrumb-item">
        <router-link :to="{name:'schedules.show', params: { uuid: this.$route.params.uuid }}">{{ model.schedule.name }}
        </router-link>
      </li>
      <li class="breadcrumb-item">{{ $tc('game.name', 2) }}</li>
      <li class="breadcrumb-item">{{ $tc('round', 1) }} {{ model.round }}</li>
      <li class="breadcrumb-item">{{ model.homeTeam.name + ' : ' + model.awayTeam.name }}</li>
    </page-header>
    <page-content>
      <div class="row d-flex mb-5">
        <div class="qr-wrapper flex-grow-1">
          <ntm-block :title="$tc('action.name', 2)">
            <template slot="options">
              <le-generate-game-news :game="model"></le-generate-game-news>
              <div class="mr-3 d-inline-block">
                <le-generate-game-image v-if="config.generateImage" :game="model"></le-generate-game-image>
                <le-copy-to-clipboard :text="qrCodeLink"></le-copy-to-clipboard>
                <le-qr-code v-if="qrCodeLink" :name="model.slug" :link="qrCodeLink"></le-qr-code>
                <le-y-t-live class="m-1" :schedule="model.schedule" :game="model" @changed="fetchData"></le-y-t-live>
              </div>
              <button type="button"
                      v-if="!isEditMode"
                      @click="editMode()"
                      :disabled="model.status === 'LIVE'"
                      class="btn btn-sm btn-success">{{ $t('game.editGameStats') }}
              </button>
              <button type="button"
                      v-if="isEditMode"
                      @click="archive()"
                      class="btn btn-sm btn-success">{{ $t('archive.action') }}
              </button>
            </template>
          </ntm-block>
          <ntm-block :collapsable="false" :title="$t('game.gameScheduling')">
            <template v-slot:options>
              <span class="badge" :class="gameStatusClass">{{ $tc(`game.status.${model.status}`, 1) }}</span>
            </template>
            <div class="d-flex justify-content-between align-items-center mb-3">
              <le-schedule-game :game="model" :venues="model.venues"></le-schedule-game> <span>{{model.time ? $d(new Date(model.time), 'dateTimeLong') : ''}}</span> <span>{{model.venue?.name}}</span>
            </div>
          </ntm-block>
        </div>
      </div>

      <ntm-block>
        <table class="table text-center">
          <thead>
          <tr>
            <th style="width: 50%;">{{ $t('home') }}</th>
            <th style="width: 50%;">{{ $t('away') }}</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td><h3 class="font-w400">{{ model.homeTeam.name }}</h3></td>
            <td><h3 class="font-w400">{{ model.awayTeam.name }}</h3></td>
          </tr>
          </tbody>
        </table>
        <h3 class="text-center"></h3>
      </ntm-block>
      <form @submit.prevent="onSubmit()">
        <ntm-block :title="$tc('information', 2)">
          <formly-form :form="form" :model="model" :fields="infoFields"></formly-form>
          <button class="btn btn-success push-15">{{ $t('save') }}</button>
        </ntm-block>
      </form>
      <div class="game-stats" v-if="isEditMode">
        <div class="row">
          <div class="col-sm-6">
            <ntm-block :title="$t('home')">
              <vue-dropzone :useCustomSlot="true" ref="myVueDropzone" class="mb-3" id="dropzone" @vdropzone-complete="fetchData()" :options="dropzoneHomeTeam">
                <div class="dropzone-custom-content">
                  <h3 class="dropzone-custom-title">{{ $tc('import.playerStats.window') }}</h3>
                  <div class="subtitle">{{ $tc('import.orSelectFromPC') }}</div>
                </div>
              </vue-dropzone>
            </ntm-block>
          </div>
          <div class="col-sm-6">
            <ntm-block :title="$t('away')">
              <vue-dropzone :useCustomSlot="true" ref="myVueDropzone" class="mb-3" id="dropzone" @vdropzone-complete="fetchData()" :options="dropzoneAwayTeam">
                <div class="dropzone-custom-content">
                  <h3 class="dropzone-custom-title">{{ $tc('import.playerStats.window') }}</h3>
                  <div class="subtitle">{{ $tc('import.orSelectFromPC') }}</div>
                </div>
              </vue-dropzone>
            </ntm-block>
          </div>
        </div>
        <ntm-block>
          <h1 class="text-center text-uppercase">{{ $tc('stats') }}</h1>
        </ntm-block>
        <form @submit.prevent="onStatSubmit()">
          <div class="row">
            <div class="col-sm-6">
              <ntm-block :title="model.homeTeam.name">
                <h3>{{ $tc('score', 1) }}: <span class="text-primary">{{ homeTeamScore }}</span></h3>
                <formly-form :form="gameStatsForm" :model="model" :fields="homeTeamStatsFields"></formly-form>
              </ntm-block>
            </div>
            <div class="col-sm-6">
              <ntm-block :title="model.awayTeam.name">
                <h3>{{ $tc('score', 1) }}: <span class="text-primary">{{ awayTeamScore }}</span></h3>
                <formly-form :form="gameStatsForm" :model="model" :fields="awayTeamStatsFields"></formly-form>
              </ntm-block>
            </div>
            <div class="col-sm-12">
              <ntm-block>
                <formly-form :form="gameStatsForm" :model="model" :fields="gameStatsFields"></formly-form>
                <button class="btn btn-success push-15">{{ $t('save') }}</button>
              </ntm-block>
            </div>
          </div>

        </form>
        <div class="row">
          <div class="col-md-6">
            <ntm-block :title="model.homeTeam.name">
              <template #options>
                <button class="btn btn-info btn-sm" @click="playerSwapOpen('homeTeam')">{{ $t('game.player.stats.swap') }}</button>
              </template>
            </ntm-block>
            <ntm-block :title="$tc('player.name', 2)">
              <ntm-list>
                <ntm-list-item v-for="player in model.homeTeam.players"
                              @playerClicked="playerStatsModal(model.homeTeam.uuid, player.uuid)" :key="player.uuid"
                               :title="player.name" :img="player.profile">
                  {{ player.number_of_jersey }}
                  <template #actions>
                    <button @click="deletePlayerStats(player)" v-if="player.hasPlayed" class="btn btn-sm btn-danger"><i class="fa fa-trash"></i></button>
                  </template>
                </ntm-list-item>
              </ntm-list>
            </ntm-block>
            <ntm-block :title="$t('team.stats')">
              <div v-if="model.homeTeam.stats" class="table-responsive">
                <table class="table">
                  <tbody>
                  <tr v-for="(stat, n) in model.homeTeam.stats" :key="n">
                    <td>{{ $t('formly.fields.' + n) }}</td>
                    <td>{{ stat }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </ntm-block>
          </div>
          <div class="col-md-6">
            <ntm-block :title="model.awayTeam.name">
              <template #options>
                <button class="btn btn-info btn-sm" @click="playerSwapOpen('awayTeam')">{{ $t('game.player.stats.swap') }}</button>
              </template>
            </ntm-block>
            <ntm-block :title="$tc('player.name', 2)">
              <ntm-list>
                <ntm-list-item v-for="player in model.awayTeam.players"
                               @playerClicked="playerStatsModal(model.awayTeam.uuid, player.uuid)" :key="player.uuid"
                               :title="player.name" :img="player.profile">
                  {{ player.number_of_jersey }}
                  <template #actions>
                    <button @click="deletePlayerStats(player)" v-if="player.hasPlayed" class="btn btn-sm btn-danger"><i class="fa fa-trash"></i></button>
                  </template>
                </ntm-list-item>
              </ntm-list>
            </ntm-block>
            <ntm-block :title="$t('team.stats')">
              <div v-if="model.awayTeam.stats" class="table-responsive">
                <table class="table">
                  <tbody>
                  <tr v-for="(stat, n) in model.awayTeam.stats" :key="n">
                    <td>{{ $t('formly.fields.' + n) }}</td>
                    <td>{{ stat }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </ntm-block>
          </div>
        </div>
        <form @submit.prevent="onCloneSubmit()">
          <ntm-block :title="$t('game.clone')">
            <formly-form :form="form" :model="cloneModel" :fields="cloneFields"></formly-form>
            <button class="btn btn-success push-15">{{ $t('game.clone') }}</button>
          </ntm-block>
        </form>
      </div>
      <ntm-block :title="$tc('action.name', 2)">
        <template slot="options">
          <button type="button"
                  @click="resetStats()"
                  class="btn btn-sm btn-warning mr-2">{{ $t('game.resetStats') }}
          </button>
          <button type="button"
                  @click="deleteGame(model.uuid)"
                  class="btn btn-sm btn-danger">{{ $t('game.delete') }}
          </button>
        </template>
      </ntm-block>
      <ntm-modal @closed="fetchData" size="lg" :title="$tc('game.player.stats.name')" ref="playerStats">
        <template slot="content">
          <formly-form :form="playerForm" :model="playerStat" :fields="playerStatFields"></formly-form>
        </template>
        <template slot="footer">
          <button class="btn btn-success push-15" @click="updatePlayerStats()">{{ $t('save') }}</button>
        </template>
      </ntm-modal>
      <modal name="swap-player-stats" :adaptive="true" height="auto" @closed="resetPlayerSwapModel()">
        <form @submit.prevent="playerSwapSubmit()">
          <ntm-block :title="$t('game.player.stats.swap')">
            <formly-form :form="playerSwapForm" :model="playerSwapModel" :fields="playerSwapFields"></formly-form>
            <button class="btn btn-success mb-3" :disabled="playerSwapLoading"><span v-if="!playerSwapLoading">{{ $t('save') }}</span><i v-if="playerSwapLoading" class="fa fa-cog fa-spin"></i></button>
          </ntm-block>
        </form>
      </modal>
    </page-content>

  </div>
</template>

<script>
import infoFields from './formDefinitions/infoFormDefinition'
import gameStatsFields from './formDefinitions/gameStatsFormDefinition'
import cloneFields from './formDefinitions/cloneFormDefinition'
import seasonShowMixin from '../../../mixins/season.show.mixin'
import GameService from '../../../services/game.service'
import router from '@/router'
import vue2Dropzone from 'vue2-dropzone'
import { TokenService } from '@/services/storage.service'
import { useExternalLinks } from '@/use/useExternalLinks'
import { useTimeline } from '@/use/useTimeline'
import LeGenerateGameImage from '@/components/le/LeGenerateGameImage.vue'
import LeScheduleGame from '@/components/le/LeScheduleGame.vue'
import LeYTLive from '@/components/le/LeYTLive.vue'

export default {
  mixins: [seasonShowMixin],
  components: {
    LeYTLive,
    LeScheduleGame,
    LeGenerateGameImage,
    vueDropzone: vue2Dropzone
  },
  data () {
    return {
      venues: [],
      playerSwapForm: {},
      playerSwapModel: {},
      playerSwapFields: [
        {
          key: 'from',
          type: 'ntm-select',
          options: [
          ],
          templateOptions: {
            customLabel: (i) => {
              if (i.number_of_jersey) {
                return `#${i.number_of_jersey} - ${i.name}`
              }

              return i.name
            },
            label: 'from'
          }
        },
        {
          key: 'to',
          type: 'ntm-select',
          options: [
          ],
          templateOptions: {
            customLabel: (i) => {
              if (i.number_of_jersey) {
                return `#${i.number_of_jersey} - ${i.name}`
              }

              return i.name
            },
            label: 'to'
          }
        }
      ],
      playerSwapLoading: false,
      form: {},
      qrCodeLink: null,
      gameStatsForm: {},
      model: {
        schedule: {},
        homeTeam: {},
        awayTeam: {}
      },
      cloneModel: {
        gameUuid: ''
      },
      cloneForm: {},
      playerForm: {},
      playerStat: {},
      playerStatFields: [],
      currentPlayer: [],
      cloneFields: cloneFields,
      infoFields: infoFields,
      gameStatsFields: gameStatsFields,
      // fields: fields,
      clubSelected: false
    }
  },
  setup () {
    const { fetchCurrentTimeline } = useTimeline()
    const { getGameWebsiteUrl } = useExternalLinks()
    return {
      fetchCurrentTimeline,
      getGameWebsiteUrl
    }
  },
  mounted () {
    this.qrCodeLink = this.getGameWebsiteUrl(this.model)
  },
  // beforeRouteUpdate (to, from, next) {
  //   GameService.show(to.params.uuid, to.params.game).then((response) => {
  //     this.setData(response.data)
  //     next()
  //   })
  // },
  beforeRouteEnter (to, from, next) {
    GameService.show(to.params.uuid, to.params.game).then((response) => {
      next(vm => vm.setData(response.data))
    })
  },
  methods: {
    generateNews () {
      GameService.generateAiNews(this.model.schedule.uuid, this.model.uuid).then((res) => {
        this.model.mustGenerateNews = res.data
      })
    },
    setData (data) {
      this.model = data
    },
    fetchData () {
      GameService.show(this.model.schedule.uuid, this.model.uuid).then((res) => {
        this.setData(res.data)
      })
    },
    resetStats () {
      GameService.reset(this.model.schedule.uuid, this.model.uuid).then(() => {
        this.fetchData()
        this.$store.commit('loaded')
      })
    },
    editMode () {
      GameService.editMode(this.model.schedule.uuid, this.model.uuid).then(() => {
        this.model.status = 'FINISHED'
        this.$store.dispatch('fetchFinishedGames')
      })
    },
    archive () {
      GameService.archive(this.model.schedule.uuid, this.model.uuid).then(() => {
        this.model.status = 'ARCHIVED'
        this.$store.dispatch('fetchFinishedGames')
      })
    },
    playerStatsModal (teamUuid, playerUuid) {
      const t = this
      t.playerStatFields = []
      t.currentPlayer = {}
      t.playerForm.$errors = []
      GameService.getPlayerStats(t.model.schedule.uuid, t.model.uuid, teamUuid, playerUuid).then((response) => {
        t.currentPlayer = {
          playerUuid: playerUuid,
          teamUuid: teamUuid
        }
        const fields = response.data.fields
        fields.forEach(function (f) {
          const o = {
            key: 'email',
            type: 'input',
            templateOptions: {}
          }
          if (f.type === 'boolean') {
            o.type = 'boolean'
          }
          o.key = f.key
          o.templateOptions.label = f.key
          switch (f.type) {
            case 'integer':
              o.templateOptions.inputType = 'number'
              break
            case 'boolean':
              o.templateOptions.inputType = 'boolean'
              break
            case 'string':
              o.templateOptions.inputType = 'text'
              break
          }
          o.templateOptions.wrapperClasses = 'col-md-6 pull-left'
          t.playerStatFields.push(o)
        })
        t.playerStat = response.data.model
        t.$refs.playerStats.open()
      })
    },
    updatePlayerStats () {
      const t = this
      t.playerStatFields.forEach(function (i) {
        if (i.templateOptions.inputType === 'number') {
          t.playerStat[i.key] = parseInt(t.playerStat[i.key])
        }
      })
      GameService.updatePlayerStats(t.model.schedule.uuid, t.model.uuid, t.currentPlayer.teamUuid, t.currentPlayer.playerUuid, t.playerStat).then(() => {
        t.$refs.playerStats.close()
        this.$store.commit('loaded')
      }).catch((err) => {
        this.playerForm.$errors = err.response.data.errors
      })
    },
    deletePlayerStats (player) {
      GameService.deletePlayerStats(this.model.schedule.uuid, this.model.uuid, player.teamUuid, player.uuid).then(() => {
        this.fetchData()
        this.$store.commit('loaded')
      }).catch((err) => {
        this.playerForm.$errors = err.response.data.errors
      })
    },
    onSubmit () {
      GameService.update(this.$route.params.uuid, this.model.uuid, this.model).then(() => {
        this.fetchData()
        this.fetchCurrentTimeline()
        this.$store.commit('loaded')
      }).catch((err) => {
        this.form.$errors = err.response.data.errors
      })
    },
    onCloneSubmit () {
      GameService.clone(this.$route.params.uuid, this.model.uuid, this.cloneModel.gameUuid).then(() => {
        router.push({ name: 'schedules.show', params: { uuid: this.$route.params.uuid } })
      }).catch((err) => {
        this.form.$errors = err.response.data.errors
        this.$store.commit('loaded')
      })
    },
    onStatSubmit () {
      GameService.updateStats(this.$route.params.uuid, this.model.uuid, this.model).then(() => {
        this.$store.commit('loaded')
      }).catch((err) => {
        this.gameStatsForm.$errors = err.response.data.errors
      })
    },
    deleteGame () {
      GameService.destroy(this.model.schedule.uuid, this.model.uuid).then(() => {
        this.$router.push({ name: 'schedules.show', params: { uuid: this.model.schedule.uuid } })
      })
    },
    playerSwapSubmit () {
      GameService.swapPlayerStats(this.model.schedule.uuid, this.model.uuid, this.playerSwapModel).then(() => {
        this.fetchData()
        this.$modal.hide('swap-player-stats')
      }).catch((err) => {
        this.playerSwapForm.$errors = err.response.data.errors
      })
    },
    playerSwapOpen (team) {
      this.setPlayerSwapOptions(team)
      this.$modal.show('swap-player-stats')
    },
    setPlayerSwapOptions (team) {
      this.playerSwapFields[0].options = this.model[team].players
      this.playerSwapFields[1].options = this.model[team].players
    },
    resetPlayerSwapModel () {
      this.playerSwapModel.from = null
      this.playerSwapModel.to = null
    }
  },
  watch: {
    finishedGames: 'fetchData'
  },
  computed: {
    gameStatusClass () {
      return {
        'badge-success': ['FINISHED', 'ARCHIVED'].includes(this.model.status),
        'badge-danger': this.model.status === 'LIVE',
        'badge-info': this.model.status === 'SCHEDULED',
        'badge-default': this.model.status === 'NOT_PLAYED'
      }
    },
    currentClient () {
      return this.$store.state.currentClient
    },
    homeTeamScore () {
      return Number(this.model.home_team_score) + Number(this.model.home_team_score_bonus)
    },
    awayTeamScore () {
      return Number(this.model.away_team_score) + Number(this.model.away_team_score_bonus)
    },
    finishedGames () {
      return this.$store.state.finishedGames
    },
    dropzoneHomeTeam () {
      return {
        url: `${process.env.VUE_APP_BASE_URL}api/v2/admin/import/game/${this.model.uuid}/team/${this.model.homeTeam.uuid}`,
        headers: {
          'X-ORGANISATION': this.currentClient.uuid,
          Authorization: `Bearer ${TokenService.getToken()}`
        },
        acceptedFiles: 'text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
      }
    },
    dropzoneAwayTeam () {
      return {
        url: `${process.env.VUE_APP_BASE_URL}api/v2/admin/import/game/${this.model.uuid}/team/${this.model.awayTeam.uuid}`,
        headers: {
          'X-ORGANISATION': this.currentClient.uuid,
          Authorization: `Bearer ${TokenService.getToken()}`
        },
        acceptedFiles: 'text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
      }
    },
    isEditMode () {
      return ['FINISHED'].includes(this.model.status)
    },
    isArchived () {
      return ['ARCHIVED'].includes(this.model.status)
    },
    config () {
      return this.$store.state.config
    },
    homeTeamStatsFields () {
      const fields = [
        {
          key: 'home_team_score',
          type: 'input',
          templateOptions: {
            inputType: 'number',
            label: 'regularPoints'
          }
        }
      ]
      let i
      if (this.config) {
        if (this.config.has_score_bonus) {
          fields.push({
            key: 'home_team_score_bonus',
            type: 'input',
            templateOptions: {
              inputType: 'number',
              label: 'bonusPoints'
            }
          })
        }

        for (i = 1; i <= this.config.periods; i++) {
          fields.push({
            key: 'home_team_period_' + i + '_score',
            type: 'input',
            templateOptions: {
              inputType: 'number',
              label: 'period_' + i
            }
          })
        }
        fields.push({
          key: 'home_team_period_0_score',
          type: 'input',
          templateOptions: {
            inputType: 'number',
            label: 'period_OT'
          }
        })
      }

      return fields
    },
    awayTeamStatsFields () {
      const fields = [
        {
          key: 'away_team_score',
          type: 'input',
          templateOptions: {
            inputType: 'number',
            label: 'regularPoints'
          }
        }
      ]
      let i
      if (this.config) {
        if (this.config.has_score_bonus) {
          fields.push({
            key: 'away_team_score_bonus',
            type: 'input',
            templateOptions: {
              inputType: 'number',
              label: 'bonusPoints'
            }
          })
        }

        for (i = 1; i <= this.config.periods; i++) {
          fields.push({
            key: 'away_team_period_' + i + '_score',
            type: 'input',
            templateOptions: {
              inputType: 'number',
              label: 'period_' + i
            }
          })
        }
        fields.push({
          key: 'away_team_period_0_score',
          type: 'input',
          templateOptions: {
            inputType: 'number',
            label: 'period_OT'
          }
        })
      }

      return fields
    }
  }
}
</script>
<style>
@media (max-width: 768px) {
  .qr-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;
  }
}
</style>
