<template>
  <div class="position-relative" style="min-height: 300px">
    <le-loading v-if="loading"></le-loading>
    <FullCalendar ref="fullCalendar" class="mb-3" :options="calendarOptions" />
  </div>
</template>

<script>
import AvailabilityService from '@/services/availability.service'
import LeLoading from '@/components/ntm/LeLoading.vue'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import dayGridPlugin from '@fullcalendar/daygrid'
import FullCalendar from '@fullcalendar/vue'
import { mapState } from 'vuex'

export default {
  components: { LeLoading, FullCalendar },
  props: {
    type: {
      type: String,
      default: 'CHOOSE'
    },
    entityType: {
      type: String,
      required: true
    },
    entityUuid: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      eventResizeListener: null,
      selectedType: null,
      loading: true,
      calendarOptions: {
        eventOverlap: false,
        firstDay: 1,
        plugins: [timeGridPlugin, interactionPlugin, dayGridPlugin],
        headerToolbar: false,
        locale: 'en',
        initialView: 'timeGridWeek',
        editable: true,
        selectable: true,
        slotMinTime: '06:00:00',
        slotMaxTime: '24:00:00',
        slotDuration: '01:00:00',
        snapDuration: '00:15:00',
        slotLabelInterval: '00:30:00',
        height: 'auto',
        dayHeaderFormat: {
          weekday: 'long'
        },
        slotLabelFormat: {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false
        },
        eventTimeFormat: {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false
        },
        buttonText: {
          today: 'Today',
          month: 'Month',
          week: 'Week',
          day: 'Day'
        },
        allDaySlot: false,
        events: this.fetchEvents,
        select: this.handleSelect,
        eventDrop: this.handleEventDrop,
        eventClick: this.handleEventClick
      },
      availability: []
    }
  },
  computed: {
    ...mapState(['language']),
    settings () {
      return this.$store.state.settings
    }
  },
  watch: {
    language (newLang) {
      // Assuming FullCalendar is initialized here
      this.$refs.fullCalendar.getApi().setOption('locale', newLang)
    }
  },
  mounted () {
    this.calendarOptions.locale = this.language
    this.calendarOptions.slotLabelFormat.hour12 = this.settings.formatOfTime === '12h-clock'
    this.calendarOptions.eventTimeFormat.hour12 = this.settings.formatOfTime === '12h-clock'

    this.selectedType = this.type === 'CHOOSE' ? 'AVAILABLE' : this.type

    this.$nextTick(() => {
      const calendarApi = this.$refs.fullCalendar.getApi()
      this.eventResizeListener = (info) => {
        const event = info.event
        AvailabilityService.update(event.extendedProps.id, {
          startDateTime: this.formatDate(event.start),
          endDateTime: this.formatDate(event.end)
        })
      }

      calendarApi.on('eventResize', this.eventResizeListener)
    })
  },
  beforeDestroy () {
    const calendarApi = this.$refs.fullCalendar.getApi()
    if (this.eventResizeListener) {
      calendarApi.off('eventResize', this.eventResizeListener)
    }
  },
  methods: {
    isSameDay (date1, date2) {
      // Check if date2 is exactly at 00:00
      if (date2.getHours() === 0 && date2.getMinutes() === 0) {
        // Allow only if date2 is exactly one day after date1
        const nextDay = new Date(date1)
        nextDay.setDate(nextDay.getDate() + 1)

        return nextDay.getFullYear() === date2.getFullYear() &&
          nextDay.getMonth() === date2.getMonth() &&
          nextDay.getDate() === date2.getDate()
        // Exclude if it's not the next day
      }

      // Standard same-day comparison
      return date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()
    },

    handleEventClick (info) {
      const event = info.event

      if (event.extendedProps.temp) {
        event.remove()
        return
      }

      AvailabilityService.delete(event.extendedProps.id).then(() => {
        event.remove()
      })
    },
    handleEventDrop (info) {
      const event = info.event

      if (event.extendedProps.temp) {
        event.remove()
        return
      }

      AvailabilityService.update(event.extendedProps.id, {
        startDateTime: this.formatDate(event.start),
        endDateTime: this.formatDate(event.end)
      })
    },
    handleSelect (selectionInfo) {
      const calendarApi = this.$refs.fullCalendar.getApi()

      const start = selectionInfo.start
      const end = selectionInfo.end

      if (!this.isSameDay(start, end)) {
        calendarApi.unselect()
        return
      }

      const newEvent = calendarApi.addEvent({
        title: this.selectedType,
        start: selectionInfo.start,
        end: selectionInfo.end,
        allDay: selectionInfo.allDay,
        backgroundColor: this.getEventColor(this.selectedType),
        extendedProps: {
          temp: true
        }
      })

      AvailabilityService.add({
        startDateTime: this.formatDate(selectionInfo.start),
        endDateTime: this.formatDate(selectionInfo.end),
        type: this.selectedType,
        entityType: this.entityType,
        entityUuid: this.entityUuid
      }).then((res) => {
        newEvent.setExtendedProp('id', res.data.id)
        newEvent.setExtendedProp('temp', false)
      }).catch(() => {
        newEvent.remove()
      })
    },
    formatDate (date) {
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0') // Dodaj nulu ako je potrebno
      const day = String(date.getDate()).padStart(2, '0')
      const hours = String(date.getHours()).padStart(2, '0')
      const minutes = String(date.getMinutes()).padStart(2, '0')

      return `${year}-${month}-${day} ${hours}:${minutes}`
    },
    getEventColor (type) {
      switch (type) {
        case 'AVAILABLE':
          return '#4CAF50'
        case 'UNAVAILABLE':
          return '#F44336'
        case 'PREFERRED':
          return '#2196F3'
        default:
          return '#FFC107'
      }
    },
    async fetchEvents (fetchInfo, successCallback, failureCallback) {
      this.loading = true
      try {
        const response = await AvailabilityService.show(this.entityType, this.entityUuid)
        console.log(response)
        const events = response.data.map(availability => this.mapAvailabilitiesToEvents(availability))

        successCallback(events)
      } catch (error) {
        failureCallback(error)
      } finally {
        this.loading = false
      }
    },
    mapAvailabilitiesToEvents (availability) {
      return {
        title: availability.type,
        start: availability.start,
        end: availability.end,
        backgroundColor: this.getEventColor(availability.type),
        extendedProps: {
          id: availability.id
        }
      }
    }
  }
}
</script>
