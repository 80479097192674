<template>
  <div>
    <div>
      <vue-dropzone id="dropzone"
                    ref="dropzone"
                    class="mb-3"
                    @vdropzone-sending="sendingEvent"
                    @vdropzone-success="moveUploadedFile"
                    :options="dropzoneOptions"></vue-dropzone>
    </div>
    <div class="row">
      <le-loading v-if="isLoading"></le-loading>
      <div class="col-sm-6 offset-sm-3" v-if="!photos.length && !isLoading">
        <h4 class="text-center mt-5 alert alert-warning">
          {{$t('game.no-photos-found')}}
        </h4>
      </div>
      <div v-for="photo in photos" :key="photo.uuid" class="col-md-6 col-lg-4 col-xl-2 animated fadeIn mb-4" style="cursor: pointer" @click="editPhoto(photo)">
        <div class="options-container fx-item-rotate-r mb-1">
          <img class="img-fluid options-item" :src="photo.coverUrl" alt="">
          <div class="options-overlay bg-black-75">
            <div class="d-flex text-white font-weight-bold">
              {{$tc('tag.add', 2)}}
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <button class="btn btn-sm btn-info" @click.stop="editPhoto(photo)">
            <i class="fa fa-plus"></i> {{$tc('tag.add', 2)}}
          </button>
          <button class="btn btn-sm btn-danger" @click.stop="deletePhoto(photo)">
            <i class="fa fa-trash"></i>
          </button>
        </div>
      </div>
    </div>
    <modal name="tags" :adaptive="true" height="auto">
      <div class="position-relative">
        <div class="p-2 text-right">
          <button class="btn btn-sm btn-danger" @click="$modal.hide('tags')">
            <i class="fa fa-close"></i>
          </button>
        </div>
        <div v-if="entityOptions" class="p-4">
          <div class="d-flex justify-content-center align-items-center mb-2">
            <img class="mw-100" style="max-height: 300px" :src="currentPhoto?.url" alt="">
          </div>
          <div class="row">
            <div class="col-sm-6">
              <le-photo-tag-entity :entity="entityOptions.homeTeam.entity" :is-active="isEntitySelected(entityOptions.homeTeam.entity)" @entityClicked="toggleEntity(entityOptions.homeTeam.entity)"></le-photo-tag-entity>

              <h5 class="mb-1 text-grey text-center font-size-sm">Players</h5>
              <le-photo-tag-entity  v-for="player in entityOptions.homeTeam.players" :key="player.entity.entityUuid" :entity="player.entity" :circle-info="player.jerseyNumber" :is-active="isEntitySelected(player.entity)" @entityClicked="toggleEntity(player.entity)"></le-photo-tag-entity>
            </div>
            <div class="col-sm-6">
              <le-photo-tag-entity :entity="entityOptions.awayTeam.entity" :is-active="isEntitySelected(entityOptions.awayTeam.entity)" @entityClicked="toggleEntity(entityOptions.awayTeam.entity)"></le-photo-tag-entity>

              <h5 class="mb-1 text-grey text-center font-size-sm">Players</h5>
              <le-photo-tag-entity v-for="player in entityOptions.awayTeam.players" :key="player.entity.entityUuid" :entity="player.entity" :circle-info="player.jerseyNumber" :is-active="isEntitySelected(player.entity)" @entityClicked="toggleEntity(player.entity)"></le-photo-tag-entity>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { TokenService } from '@/services/storage.service'
import vue2Dropzone from 'vue2-dropzone'
import PhotoService from '@/services/photo.service'
import LePhotoTagEntity from '@/components/ntm/LePhotoTagEntity.vue'
import LeLoading from '@/components/ntm/LeLoading.vue'

export default {
  components: {
    LeLoading,
    LePhotoTagEntity,
    vueDropzone: vue2Dropzone
  },
  props: {
    gameUuid: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isLoading: true,
      photos: [],
      entityOptions: null,
      currentPhoto: null,
      currentPhotoTags: []
    }
  },
  mounted () {
    this.getPhotos()
    this.getEntityOptions()
  },
  computed: {
    currentClient () {
      return this.$store.state.currentClient
    },
    dropzoneOptions () {
      return {
        url: `${process.env.VUE_APP_BASE_URL}api/photos`,
        autoProcessQueue: true,
        uploadMultiple: false,
        parallelUploads: 1,
        maxFilesize: 20,
        addRemoveLinks: true,
        headers: {
          'X-ORGANISATION': this.currentClient.uuid,
          Authorization: `Bearer ${TokenService.getToken()}`,
          Accept: '*'
        }
      }
    }
  },
  methods: {
    toggleEntity (entity) {
      const tagIndex = this.currentPhotoTags.findIndex(tag => tag.entityUuid === entity.entityUuid)

      if (tagIndex === -1) {
        PhotoService.addTag(this.currentPhoto.uuid, {
          entityUuid: entity.entityUuid,
          entityType: entity.entityType
        })

        this.currentPhotoTags.push({
          entityUuid: entity.entityUuid,
          entityType: entity.entityType
        })
      } else {
        PhotoService.deleteTag(this.currentPhoto.uuid, {
          entityUuid: entity.entityUuid,
          entityType: entity.entityType
        })

        this.currentPhotoTags.splice(tagIndex, 1)
      }
    },
    isEntitySelected (entity) {
      return this.currentPhotoTags.some(tag => tag.entityUuid === entity.entityUuid && tag.entityType === entity.entityType)
    },
    async editPhoto (photo) {
      this.currentPhoto = photo
      await this.getPhotoTags(photo)
      this.$modal.show('tags')
    },
    deletePhoto (photo) {
      this.$modal.hide('tags')
      PhotoService.delete(photo.uuid)
      this.photos = this.photos.filter(p => p.uuid !== photo.uuid)
    },
    async getPhotoTags (photo) {
      const response = await PhotoService.getTags(photo.uuid, 'game')
      this.currentPhotoTags = response.data
    },
    getEntityOptions () {
      PhotoService.getGameEntityOptions(this.gameUuid)
        .then(response => {
          this.entityOptions = response.data
        })
    },
    getPhotos () {
      this.isLoading = true
      PhotoService.index('game', this.gameUuid)
        .then(response => {
          this.photos = response.data
        })
        .catch(e => {
          console.log(e)
        }).finally(() => {
          this.isLoading = false
        })
    },
    moveUploadedFile (file, responseData) {
      console.log('File uploaded successfully:', file, responseData)
      setTimeout(() => {
        if (file.status === 'success') {
          this.photos.push(responseData)
        }

        this.$refs.dropzone.removeFile(file)
      }, 1000)
    },
    sendingEvent (file, xhr, formData) {
      formData.append('entityUuid', this.gameUuid)
      formData.append('entityType', 'game')
    }
  }
}
</script>

<style>
.dropzone {
  max-height: 250px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.dz-image img {
  object-fit: cover; /* Ensures images fit within the box */
}
</style>
