import ApiService from './api.service'

const PhotoService = {
  index (entityType, entityUuid) {
    return ApiService.get('api/photos', {
      params: {
        entityType,
        entityUuid
      }
    })
  },

  store (data) {
    return ApiService.post('api/photos', data)
  },

  delete (photoUuid) {
    return ApiService.deleteWithoutAsking('api/photos/' + photoUuid)
  },

  getTags (photoUuid, ignoreEntityType = undefined) {
    return ApiService.get(`api/photos/${photoUuid}/tags`, {
      params: {
        ignoreEntityType
      }
    })
  },
  addTag (photoUuid, data) {
    return ApiService.postWithoutLoading(`api/photos/${photoUuid}/tags`, data)
  },
  deleteTag (photoUuid, data) {
    return ApiService.deleteWithoutAsking(`api/photos/${photoUuid}/tags`, {
      params: {
        entityType: data.entityType,
        entityUuid: data.entityUuid
      }
    })
  },
  getGameEntityOptions (gameUuid) {
    return ApiService.get(`api/photos/tag-options/game/${gameUuid}`)
  }
}

export default PhotoService
