<template>
  <div class="position-relative" style="min-height: 500px">
    <le-loading v-if="isLoading"></le-loading>
    <div v-for="(date, n) in model.games" :key="`date-${n}`">
      <ntm-block>
        <h2>{{ $d(new Date(n.replace('T', ' ').replace(/-/g, '/')), 'dateLong') }}</h2>
        <div v-for="(venue, n) in date" :key="`venue-${n}`" class="venue-caption">
          <h3>{{ n }}</h3>
          <table class="table table-bordered table-striped table-vcenter">
            <thead>
            <tr>
              <th>Code</th>
              <th>Time</th>
              <th>League</th>
              <th class="text-center">Game</th>
              <th class="text-center">Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="game in venue" :key="game.uuid" :class="gameStatusColor(game.status)">
              <td>
                <template v-if="['SCHEDULED', 'FINISHED'].includes(game.status)">
                  <div class="btn-group">
                    <button class="btn btn-info btn-sm"
                            v-if="['SCHEDULED', 'FINISHED'].includes(game.status) && game.teamA && game.teamB"
                            @click="scheduleGameModal(game)"><i class="fa fa-clock-o"></i></button>
                    <button class="btn btn-warning btn-sm"
                            v-if="['SCHEDULED'].includes(game.status) && game.teamA && game.teamB"
                            @click="cancelScheduledGame(game)"><i class="fa fa-times"></i></button>
                  </div>
                </template>
                <template v-if="['ARCHIVED'].includes(game.status)">
                  <button @click="generateCode(game)" class="btn btn-sm btn-warning">
                    <i class="fa fa-undo"></i>
                  </button>
                </template>
                <span class="ml-2" :class="{'text-warning': newCode === game.code}" v-if="game.code">{{
                    game.code
                  }}</span>
              </td>
              <td>{{ $d(new Date(game.time.replace('T', ' ').replace(/-/g, '/')), 'time') }}</td>
              <td>{{ game.league.name }}</td>
              <td class="text-center"><strong>{{ game.teamA?.name ?? '?' }}</strong> :
                <strong>{{ game.teamB?.name ?? '?' }}</strong></td>
              <td class="text-center">
                <le-copy-to-clipboard v-if="!['ARCHIVED'].includes(game.status)" icon="fa fa-calculator" class="m-1"
                                      :text="getScorekeeperGameUrl(game.code)"></le-copy-to-clipboard>
                <le-generate-game-news :game="game"></le-generate-game-news>
                <le-copy-to-clipboard icon="fa fa-globe" class="m-1"
                                      :text="getGameWebsiteUrl(game)"></le-copy-to-clipboard>
                <game-embed-video class="m-1" :game="game" @changed="fetchData"></game-embed-video>
                <le-y-t-live class="m-1" :schedule="game.schedule" :disable-buttons="!['SCHEDULED', 'LIVE'].includes(game.status)" :game="game" @changed="fetchData"></le-y-t-live>
                <router-link
                  :to="{ name: 'schedules.games.showGallery', params: {uuid: game.schedule.uuid, game: game.uuid }}"
                  class="btn btn-sm btn-primary">
                  <i class="fa fa-image"></i>
                </router-link>
                <button v-if="['NOT_PLAYED', 'SCHEDULED'].includes(game.status)" @click="swapGameTimeAndVenue(game)" :class="{'btn-success': swapGame?.uuid === game.uuid, 'btn-info': swapGame?.uuid !== game.uuid}" class="btn btn-sm m-1"><i class="fa fa-arrows-v"></i></button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </ntm-block>
    </div>
    <modal name="schedule-modal" :adaptive="true" height="auto">
      <form @submit.prevent="scheduleGameSubmit()">
        <ntm-block :title="scheduleGameTitle">
          <formly-form :form="scheduleGameForm" :model="scheduleGameModel" :fields="scheduleGameFields"></formly-form>
          <button class="btn btn-success push-15" :disabled="scheduleGameLoading"><span
            v-if="!scheduleGameLoading">{{ $t('save') }}</span><i v-if="scheduleGameLoading"
                                                                  class="fa fa-cog fa-spin"></i></button>
        </ntm-block>
      </form>
    </modal>
  </div>
</template>

<script>
import GameEmbedVideo from '@/components/le/GameEmbedVideo.vue'
import LeYTLive from '@/components/le/LeYTLive.vue'
import scheduleFormDefinition from '@/views/schedules/games/formDefinitions/scheduleFormDefinition'
import { gameStatusColor } from '@/lib/status'
import GameService from '@/services/game.service'
import VueI18n from '@/i18n'
import DashboardService from '@/services/dashboard.service'
import { useRouteQuery } from '@/use/useRouteQuery'
import { ref } from 'vue'
import { useGameSwap } from '@/use/useGameSwap'
import { useTimeline } from '@/use/useTimeline'
import { useExternalLinks } from '@/use/useExternalLinks'
import LeLoading from '@/components/ntm/LeLoading.vue'

export default {
  components: { LeLoading, LeYTLive, GameEmbedVideo },
  data () {
    return {
      view: 'calendar',
      scheduleGameLoading: false,
      scheduleGameModel: {},
      scheduleGameForm: {},
      scheduleGameFields: scheduleFormDefinition,
      newCode: null
    }
  },
  setup () {
    const currentTimeline = useRouteQuery('timeline', 'all')
    const model = ref({})
    const isLoading = ref(false)
    function setData (data) {
      model.value = data
    }
    function fetchData () {
      isLoading.value = true
      DashboardService.index(currentTimeline.value).then((res) => {
        setData(res.data)
      }).finally(() => {
        isLoading.value = false
      })
    }

    const { swapGame, swapGameTimeAndVenue } = useGameSwap(() => fetchData())

    const { timeline, fetchCurrentTimeline, publishTimeline } = useTimeline(() => {
      currentTimeline.value = 'all'

      setTimeout(() => {
        fetchData()
      })
    })
    const { getScorekeeperGameUrl, getGameWebsiteUrl, getGameBroadcastWebsiteUrl } = useExternalLinks()

    return {
      isLoading,
      model,
      swapGame,
      swapGameTimeAndVenue,
      setData,
      fetchData,
      getGameBroadcastWebsiteUrl,
      getScorekeeperGameUrl,
      getGameWebsiteUrl,
      timeline,
      fetchCurrentTimeline,
      publishTimeline,
      currentTimeline
    }
  },
  mounted () {
    this.fetchData()
  },
  watch: {
    currentTimeline: 'fetchData'
  },
  methods: {
    gameStatusColor,
    scheduleGameModal (game) {
      this.scheduleGameModel = game
      this.$modal.show('schedule-modal')
    },
    cancelScheduledGame (game) {
      GameService.cancelScheduledGame(game.schedule.uuid, game.uuid).then(() => {
        this.fetchData()
        this.fetchCurrentTimeline()
      })
    },
    scheduleGameSubmit () {
      this.scheduleGameLoading = true
      GameService.scheduleGame(this.scheduleGameModel.schedule.uuid, this.scheduleGameModel.uuid, {
        time: this.scheduleGameModel.time,
        venue: this.scheduleGameModel.venue,
        info: this.scheduleGameModel.info
      }).then((res) => {
        this.$modal.hide('schedule-modal')
        this.fetchData()
        this.fetchCurrentTimeline()
      }).catch((err) => {
        this.scheduleGameForm.$errors = err.response.data.errors
      }).finally(() => {
        this.scheduleGameLoading = false
      })
    },
    scheduleGameTitle () {
      const homeTeamName = this.scheduleGameModel?.teamA?.name
      const awayTeamName = this.scheduleGameModel?.teamB?.name
      return `${homeTeamName} --:-- ${awayTeamName}`
    },
    generateCode (game) {
      this.$swal({
        title: VueI18n.t('swal.generateCode.title'),
        text: VueI18n.t('swal.generateCode.text'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: VueI18n.t('swal.generateCode.yes'),
        cancelButtonText: VueI18n.t('swal.generateCode.no'),
        showCloseButton: true,
        showLoaderOnConfirm: true
      }).then((result) => {
        if (!result.value) return

        DashboardService.generateCode(game.uuid).then((res) => {
          game.code = res.data.code
          game.status = res.data.status

          this.newCode = res.data.code

          this.$copyText(res.data.code).then(() => {
            // this.$notify('Copied to clipboard', 'success')
            this.$toast.fire({
              title: 'Copied to clipboard',
              icon: 'success'
            })
          })

          setTimeout(() => {
            this.newCode = null
          }, 10000)
        })
      })
    }
  }
}

</script>
