<template>
  <a @click="entityClicked()" :class="{'border-grey': !isActive, 'border-success': isActive}"
     class="d-flex py-2 mb-2 photo-tag-entity">
    <div class="flex-shrink-0 px-2 position-relative mr-2">
      <img v-if="entity.entityImageUrl" class="img-avatar img-avatar48 border border-3x" :class="{'border-grey': !isActive, 'border-success': isActive}" :src="entity.entityImageUrl" alt="">
      <img v-else class="img-avatar img-avatar48 border border-3x" :class="{'border-grey': !isActive, 'border-success': isActive}" src="@/assets/media/avatars/avatar10.jpg" alt="">
      <span v-if="circleInfo" class="photo-tag-entity-circle-info text-white" :class="{'bg-grey': !isActive, 'bg-success': isActive}">{{circleInfo}}</span>
    </div>
    <div class="flex-grow-1">
      <div class="fw-semibold">{{ entity.entityName }}</div>
      <span class="badge" :class="{'badge-default': !isActive, 'badge-success': isActive}">{{ $tc(`${entity.entityType}.name`, 1) }}</span>
    </div>
  </a>
</template>

<script>
export default {
  emits: ['entityClicked'],
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    circleInfo: {
      type: String,
      default: ''
    },
    entity: {
      type: Object,
      default: () => {
      }
    }
  },
  methods: {
    entityClicked () {
      this.$emit('entityClicked', this.entity)
    }
  }
}
</script>

<style>
.photo-tag-entity {
  border: 1px solid #0b0b0b;
  cursor: pointer;
  border-radius: 5px;
}

.photo-tag-entity-circle-info {
  position: absolute;
  display: flex;
  bottom: 0;
  right: 0;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
}
</style>
