import { useStore } from '@/store'

const store = useStore()
export function useExternalLinks () {
  function getWebsiteDomain () {
    const domain = store.state.domains?.WEBSITE

    if (domain === 'localhost') {
      return 'http://localhost:6636'
    }

    return `https://${domain}`
  }

  function getScorekeeperDomain () {
    return process.env.VUE_APP_SCOREKEEPER_URL
  }

  function getScorekeeperGameUrl (code) {
    return `${getScorekeeperDomain()}/${store.state.currentClient.uuid}/games/${code}/pre-game`
  }

  function getGameWebsiteUrl (game) {
    const domain = getWebsiteDomain()

    if (game.duelSlug) {
      return `${domain}/seasons/${game.seasonSlug}/leagues/${game.leagueSlug}/playoff/${game.playoffRoundSlug}/${game.duelSlug}/games/${game.round}`
    }

    return `${domain}/seasons/${game.seasonSlug}/leagues/${game.leagueSlug}/rounds/${game.round}/games/${game.slug}`
  }

  function getGameBroadcastWebsiteUrl (game) {
    return `${getGameWebsiteUrl(game)}/broadcast`
  }

  function getNewsItemWebsiteUrl (newsItem) {
    const domain = getWebsiteDomain()

    return `${domain}/news/${newsItem.slug}`
  }

  return {
    getWebsiteDomain,
    getScorekeeperDomain,
    getScorekeeperGameUrl,
    getGameWebsiteUrl,
    getGameBroadcastWebsiteUrl,
    getNewsItemWebsiteUrl
  }
}
