<template>
  <div v-if="model.schedule">
    <page-header :title="$t('game.gallery')">
      <li class="breadcrumb-item">
        <router-link :to="{name:'schedules.index'}">{{ $tc('schedule.name', 2) }}</router-link>
      </li>
      <li class="breadcrumb-item">
        <router-link :to="{name:'schedules.show', params: { uuid: this.$route.params.uuid }}">{{ model.schedule.name }}
        </router-link>
      </li>
      <li class="breadcrumb-item">{{ $tc('game.name', 2) }}</li>
      <li class="breadcrumb-item">{{ $tc('round', 1) }} {{ model.round }}</li>
      <li class="breadcrumb-item">{{ model.homeTeam.name + ' : ' + model.awayTeam.name }}</li>
      <li class="breadcrumb-item">{{ $t('game.gallery') }}</li>
    </page-header>
    <page-content>
      <le-game-photo-gallery :game-uuid="model.uuid"></le-game-photo-gallery>
    </page-content>

  </div>
</template>

<script>
import seasonShowMixin from '../../../mixins/season.show.mixin'
import GameService from '@/services/game.service'
import LeGamePhotoGallery from '@/components/ntm/LeGamePhotoGallery.vue'

export default {
  mixins: [seasonShowMixin],
  components: {
    LeGamePhotoGallery
  },
  data () {
    return {
      model: {
        schedule: null,
        round: null,
        homeTeam: null,
        awayTeam: null
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    GameService.showGallery(to.params.uuid, to.params.game).then((response) => {
      next(vm => vm.setData(response.data))
    })
  }
}
</script>
