<template>
  <div class="form-group"
       :class="[to.inputType, {'formly-has-value': model[field.key], 'formly-has-focus': form[field.key].$active, 'has-error': hasError}]">

    <label v-if="to.label" v-text="$t('formly.fields.' + to.label)" :for="field.key"></label>

    <date-picker
      :value="parsedValue"
      @input="updateValue"
      mode="dateTime"
      :locale="clientSettings?.lang"
      :minute-increment="5"
      :is24hr="clientSettings?.formatOfTime === '24h-clock'"
      :popover="{ visibility: 'none', keepVisibleOnInput: false }"
    >

      <template v-slot="{ inputValue, inputEvents, togglePopover }">
        <div class="datetime-picker">
          <button
            type="button"
            class="datetime-button"
            @click="togglePopover()"
          >
            <i class="fa fa-calendar"></i>
          </button>
          <input
            id="date"
            class="datetime-input"
            :value="inputValue"
            v-on="inputEvents"
          />
        </div>

      </template>

    </date-picker>

    <span class="help-block form-text text-danger"
          v-if="form.$errors[field.key] && form.$errors[field.key].length > 0"
          v-text="$t('validation.' + field.key + '.' + form.$errors[field.key][0])" />
  </div>
</template>

<script>
import { DatePicker } from 'v-calendar/src/components'
import baseField from '@/components/formly/formly-bootstrap/fields/baseField'

export default {
  components: { DatePicker },
  mixins: [baseField],
  computed: {
    clientSettings () {
      return this.$store.state.settings
    },
    parsedValue () {
      return this.model[this.field.key] ? new Date(this.model[this.field.key]) : null
    }
  },
  methods: {
    getDateTimeFormat () {
      const dateFormat = this.clientSettings?.formatOfDate
      const timeFormat = this.clientSettings?.formatOfTime === '24h-clock' ? 'HH:mm' : 'hh:mm a'

      let formattedDate
      switch (dateFormat) {
        case 'DMY':
          formattedDate = 'dd-MM-yyyy'
          break
        case 'YMD':
          formattedDate = 'yyyy-MM-dd'
          break
        case 'MDY':
          formattedDate = 'MM-dd-yyyy'
          break
        default:
          formattedDate = 'yyyy-MM-dd'
      }

      return `${formattedDate} ${timeFormat}`
    },
    updateValue (date) {
      if (!date) {
        this.model[this.field.key] = null
        return
      }

      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0') // Meseci su 0-indeksirani
      const day = String(date.getDate()).padStart(2, '0')
      const hours = String(date.getHours()).padStart(2, '0')
      const minutes = String(date.getMinutes()).padStart(2, '0')
      const seconds = String(date.getSeconds()).padStart(2, '0')

      this.model[this.field.key] = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
    }
  }
}
</script>
