import ApiService from './api.service'

const AvailabilityService = {
  add (data) {
    return ApiService.postWithoutLoading('api/availability/add', data)
  },
  update (id, data) {
    return ApiService.putWithoutLoading(`api/availability/${id}`, data)
  },
  delete (id, data) {
    return ApiService.deleteWithoutLoading(`api/availability/${id}`)
  },
  upsert (data) {
    return ApiService.postWithoutLoading('api/availability', data)
  },
  show (entity, uuid) {
    return ApiService.get(`api/availability/${entity}/${uuid}`)
  }
}

export default AvailabilityService
