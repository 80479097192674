<template>
  <div class="schedule_index">
    <page-header :title="$tc('schedule.name', 2)"></page-header>
    <page-content>
      <div class="row">
        <div class="col-lg-8 offset-lg-2">
          <ntm-block>
            <template slot="options">
              <div class="flex flex-column">
              <multiselect :allowEmpty="false" style="min-width: 150px;" class="d-none d-inline-block" track-by="uuid"
                           v-model="currentSeason" :options="seasonOptions" @search-change="getOptions"
                           :customLabel="customLabel"></multiselect>
              <!--<input type="text" class="form-control" :placeholder="$t('search')"  v-model="search">-->
              <button @click="createPage()" type="button" class="btn btn-sm btn-success">{{$t('action.addNew')}}</button>

              </div>
            </template>
          </ntm-block>
          <div class="items-wrapper position-relative">
            <le-loading v-if="loading"></le-loading>
              <draggable handle=".drag-handle"
                         @change="changeOrder"
                         v-bind="dragOptions"
                         v-model="model"
                         @start="drag = true"
                         @end="drag = false">
                <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                  <div class="block block-rounded mb-3" v-for="item in model" :key="item.uuid">
                    <div class="block-header" :class="{'bg-bisque': !item.showOnLandingPage}">
                      <h3 class="block-title"><i class="fa fa-bars drag-handle mr-3"></i> {{item.name}}</h3>
                      <div class="block-options">
                        <router-link :to="{ name: 'schedules.show', params: {uuid: item.uuid }}" class="btn btn-sm btn-primary">
                          <i class="fa fa-fw fa-eye"></i>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </transition-group>
              </draggable>
          </div>
        </div>
      </div>
      <ntm-modal size="lg" :title="$tc('schedule.create')" ref="newSchedule">
        <template slot="content">
            <formly-form :form="createScheduleForm" :model="createScheduleModel" :fields="createScheduleFields"></formly-form>
        </template>
        <template slot="footer">
          <button class="btn btn-success push-15" @click="onAddSchedule()">{{$t('save')}}</button>
        </template>
      </ntm-modal>
      <!-- END Full Table -->
    </page-content>
  </div>
</template>
<script>
import ScheduleService from '@/services/schedule.service'
import fields from './formDefinitions/createScheduleFormDefinition'
import seasonIndexMixin from '@/mixins/season.index.mixin'
import draggable from 'vuedraggable'
import Multiselect from 'vue-multiselect'
import LeLoading from '@/components/ntm/LeLoading.vue'
export default {
  mixins: [seasonIndexMixin],
  components: {
    LeLoading,
    Multiselect,
    draggable
  },
  data () {
    return {
      drag: false,
      dragOptions: {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      },
      createScheduleForm: {},
      createScheduleModel: {},
      createScheduleFields: fields
    }
  },
  mounted () {
    this.getOptions()
  },
  computed: {
    config () {
      return this.$store.state.config
    },
    seasonOptions () {
      return this.$store.state.seasonOptions
    },
    currentSeason: {
      get: function () {
        return this.$store.state.currentSeason
      },
      // setter
      set: function (newValue) {
        this.$store.commit('setCurrentSeason', newValue)
      }
    },
    extendScheduleOptions () {
      return this.model?.filter(s => s.league.uuid !== this.createScheduleModel?.league?.uuid)
    },
    newLeagueModel () {
      return {
        extendsSchedules: [],
        league: null,
        suffix: null,
        periodTime: this.$store.state.config.period_time / 60 / 1000,
        minTeamPlayers: this.$store.state.config.isScout ? 0 : this.$store.state.config.min_team_players,
        maxTeamPlayers: this.$store.state.config.isScout ? null : this.$store.state.config.max_team_players,
        hideLeagueRankings: this.$store.state.config.isScout,
        gameDurationInMinutes: this.$store.state.config.gameDurationInMinutes,
        breakBetweenGamesInMinutes: this.$store.state.config.breakBetweenGamesInMinutes,
        isTournament: false,
        showOnLandingPage: true,
        aiLeaguePreviousWeekSummaryNews: 'EVERY_MONDAY',
        aiGamesNews: ['CLOSE_GAMES', 'IMPORTANT_GAMES'],
        aiPlayerRecordsNews: true
      }
    }
  },
  watch: {
    extendScheduleOptions: {
      handler: function (newValue, oldValue) {
        this.updateExtendsScheduleOptions()
      }
    }
  },
  methods: {
    customLabel (o) {
      return `${o.name ? o.name : ''}`
    },
    getOptions (q = '') {
      this.$store.dispatch('getSeasonOptions', q)
    },
    changeOrder (data) {
      ScheduleService.changeOrder(this.model)
    },
    fetchData () {
      ScheduleService.index().then((response) => {
        this.setData(response.data)
      })
    },
    setData (data) {
      this.model = data
      this.createScheduleModel = this.newLeagueModel
      this.loading = false
      if (this.config.ai) {
        if (!this.createScheduleFields.find(f => f.key === 'aiGamesNews')) {
          this.createScheduleFields.push({
            key: 'aiGamesNews',
            type: 'ntm-select',
            multiple: true,
            isString: true,
            options: [
              'ALL_GAMES',
              'CLOSE_GAMES',
              'IMPORTANT_GAMES'
            ],
            templateOptions: {
              label: 'aiGamesNews',
              translated: true
            }
          })
        }

        if (!this.createScheduleFields.find(f => f.key === 'aiLeaguePreviousWeekSummaryNews')) {
          this.createScheduleFields.push({
            key: 'aiLeaguePreviousWeekSummaryNews',
            type: 'ntm-select',
            isString: true,
            options: [
              'OFF',
              'EVERY_MONDAY',
              'EVERY_TUESDAY',
              'EVERY_WEDNESDAY',
              'EVERY_THURSDAY',
              'EVERY_FRIDAY',
              'EVERY_SATURDAY',
              'EVERY_SUNDAY'
            ],
            templateOptions: {
              translated: true,
              label: 'aiLeaguePreviousWeekSummaryNews'
            }
          })
        }

        if (!this.createScheduleFields.find(f => f.key === 'aiPlayerRecordsNews')) {
          this.createScheduleFields.push({
            key: 'aiPlayerRecordsNews',
            type: 'boolean',
            templateOptions: {
              label: 'aiPlayerRecordsNews'
            }
          })
        }
      }
    },
    updateExtendsScheduleOptions () {
      this.createScheduleFields.find(f => f.key === 'extendsSchedules').options = this.extendScheduleOptions

      if (this.createScheduleModel.extendsSchedules) {
        this.createScheduleModel.extendsSchedules = this.createScheduleModel.extendsSchedules.filter(s => s.league.uuid !== this.createScheduleModel?.league?.uuid)
      }
    },
    createPage () {
      this.$refs.newSchedule.open()
    },
    onAddSchedule () {
      this.createScheduleModel.season = this.currentSeason.uuid
      ScheduleService.store(this.createScheduleModel).then((res) => {
        this.$refs.newSchedule.close()
        this.createScheduleModel = this.newLeagueModel
        this.$router.push({ name: 'schedules.show', params: { uuid: res.data.uuid } })
      }).catch((err) => {
        this.createScheduleForm.$errors = err.response.data.errors
      }).finally(() => {
        this.$store.commit('loaded')
      })
    }
  }
}
</script>
