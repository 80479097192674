const fields = [
  {
    key: 'name',
    type: 'input',
    templateOptions: {
      label: 'name'
    }
  },
  {
    key: 'backgroundColor',
    type: 'color',
    templateOptions: {
      label: 'backgroundColor'
    }
  },
  {
    key: 'textColor',
    type: 'color',
    templateOptions: {
      label: 'textColor'
    }
  }
]

export default fields
