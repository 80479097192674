<template>
  <div class="schedule_show">
    <page-header :title="model.name" :desc="$tc('schedule.name', 1)">
      <li class="breadcrumb-item">
        <router-link :to="{name:'schedules.index'}">{{ $tc('schedule.name', 2) }}</router-link>
      </li>
      <li class="breadcrumb-item">{{ model.name }}</li>
    </page-header>
    <page-content>
      <ntm-block class="short-block" :title="$tc('action.name', 2)">
        <template slot="options">
          <!--<input type="text" class="form-control" placeholder="Search"  v-model="search">-->
          <button type="button"
                  v-if="!model.playoff"
                  @click="$router.push({name: 'schedules.createPlayoff.show', params: {uuid: model.uuid}})"
                  class="btn btn-sm btn-warning">{{$t('playoff.create')}}
          </button>
          <button type="button"
                  v-if="model.playoff"
                  @click="$router.push({name: 'schedules.playoff.show', params: {uuid: model.uuid}})"
                  class="btn btn-sm btn-success">Playoff
          </button>
          <button type="button"
                  @click="$refs.addGame.open()"
                  class="btn btn-sm btn-success">{{ $t('game.create') }}
          </button>
          <button type="button" class="btn btn-sm btn-success" @click="addGroup()">{{ $t('group.add') }}</button>
          <button v-if="model.games && Object.keys(model.games).length < 2"
                  type="button"
                  class="btn btn-sm btn-warning"
                  @click="$refs.generateSchedule.open()">{{ $t('schedule.games.generate') }}
          </button>
          <!--          <button-->
          <!--            type="button"-->
          <!--            v-if="!nameImagesLoading"-->
          <!--            class="btn btn-sm btn-warning"-->
          <!--            @click="nameImagesOfAllPlayers()"><i class="fa fa-picture-o"></i>-->
          <!--          </button>-->
          <!--          <button-->
          <!--            type="button"-->
          <!--            v-if="nameImagesLoading"-->
          <!--            class="btn btn-sm btn-warning"-->
          <!--            @click="nameImagesOfAllPlayers()"><i class="fa fa-spin fa-spinner"></i>-->
          <!--          </button>-->
          <button type="button"
                  @click="$refs.fantasyCreate.open()"
                  :class="{'btn-warning': !model.fantasyLeague?.uuid, 'btn-info': model.fantasyLeague?.uuid}"
                  class="btn btn-sm">{{model.fantasyLeague?.uuid ? $t('fantasy.edit'):  $t('fantasy.create')}}
          </button>
          <button
            type="button"
            class="btn btn-sm btn-info"
            @click="$router.push({name: 'schedules.venueVideos', params: {uuid: model.uuid}})">
            <i class="fa fa-video-camera"></i>
          </button>
          <button
            type="button"
            class="btn btn-sm btn-info"
            @click="$refs.editSchedule.open()"><i class="fa fa-pencil"></i>
          </button>

          <!--<button v-if="Object.keys(model.games).length > 0"-->
          <!--type="button"-->
          <!--class="btn btn-sm btn-warning"-->
          <!--@click="regenerate()">{{$t('schedule.games.regenerate')}}-->
          <!--</button>-->
        </template>
      </ntm-block>
      <ntm-block v-if="model.medals.length">
        <div class="row mb-3">
          <div class="col-sm-12 mb-2" v-for="medal in model.medals" :key="medal.uuid">
            <span :class="getMedalClass(medal.place)"
                  class="tournament-bracket__medal tournament-bracket__medal fa fa-trophy"></span> {{ medal.name }}
          </div>
        </div>
      </ntm-block>
      <div class="groups short-block">
        <ntm-block v-for="(group, n) in model.groups"
                   :save-collapse="`schedule-${model.uuid}-group-${group.name}`"
                   :key="group.uuid"
                   :title="model.groups.length > 1 ? $tc('group.name', 1) + ' ' + group.name : $tc('team.name', 2)">
          <template slot="options">
            <!--<input type="text" class="form-control" placeholder="Search"  v-model="search">-->
            <button type="button"
                    class="btn btn-sm btn-danger"
                    v-if="n !== 0"
                    @click="deleteGroup(group.uuid)"><i class="fa fa-fw fa-trash"></i></button>
          </template>
          <AddTeamForm :key="`add-team-form-${group.uuid}`" :init-open="!group.teams?.length"
                       :schedule-uuid="$route.params.uuid" :group-uuid="group.uuid"
                       @teamAdded="fetchData"></AddTeamForm>
          <div class="row" v-if="group.teams?.length" :id="group.uuid">
            <div class="team col-sm-12"
                 v-for="team in orderedTeams(group.teams)"
                 :id="team.uuid"
                 :key="team.uuid">
              <div class="team-wrapper">
                <span v-if="team.name">{{ team.name }}</span>
                <span v-else><span class="badge badge-info">{{ $t('emptySlot') }}</span></span>
                <div class="team-right-side">
                  <team-players-images-download :schedule="model" :team="team"></team-players-images-download>
                  <router-link
                    :to="{ name: 'schedules.teams.show', params: {uuid: $route.params.uuid, team: team.uuid }}"
                    :class="{'btn-success': team.players_count >= model.minTeamPlayers, 'btn-warning': team.players_count < model.minTeamPlayers}"
                    class="team-right-side-item btn btn-sm mr-2">
                    <i class="fa fa-users"></i>
                    <span class="ml-1">{{ team.players_count }}</span>
                  </router-link>
                  <router-link
                    :to="{ name: 'schedules.teams.show', params: {uuid: $route.params.uuid, team: team.uuid }}"
                    class="btn btn-sm btn-primary">
                    <i class="fa fa-pencil"></i>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </ntm-block>
      </div>
      <ntm-block v-if="model.playoffGames && model.playoffGames.length > 0" :title="$tc('playoff.game', 2)">
        <template slot="options">

        </template>
        <div class="row">
          <div v-for="round in model.playoffGames" :key="round.round" class="game-round col-12">
            <h3>{{ getNameOfRound(round.round) }}</h3>
            <div v-for="(gameRound, gameRoundKey) in groupByGameRound(round)" :key="gameRoundKey">
              <h5 v-if="Object.keys(groupByGameRound(round)).length > 1" class="text-info">{{ $tc('game.name', 1) }} -
                {{ gameRoundKey }}</h5>
              <div class="table-responsive">
                <table class="table table-vcenter table-striped">
                  <thead>
                  <tr>
                    <th>{{ $t('code') }}</th>
                    <th>{{ $t('time') }}</th>
                    <th class="text-right" style="min-width: 300px">{{ $t('home') }}</th>
                    <th style="min-width: 85px" class="no-wrap"></th>
                    <th class="text-left" style="min-width: 300px">{{ $t('away') }}</th>
                    <th class="text-center" style="width: 100px;">{{ $tc('action.name', 2) }}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="game in gameRound" :key="game.uuid" :class="gameStatusColor(game.status)">
                    <td>
                      <div class="btn-group">
                        <button class="btn btn-info btn-sm"
                                v-if="['NOT_PLAYED', 'SCHEDULED', 'FINISHED'].includes(game.status)"
                                @click="scheduleGameModal(game)"><i class="fa fa-clock-o"></i></button>
                        <button class="btn btn-warning btn-sm"
                                v-if="['SCHEDULED'].includes(game.status)"
                                @click="cancelScheduledGame(game)"><i class="fa fa-times"></i></button>
                      </div>
                      <span class="ml-2">{{ game.code }}</span>
                    </td>
                    <td><strong style="font-size: 12px;">{{ formatDate(game.time) }}</strong></td>
                    <td class="font-w600 font-size-sm text-right">
                    <span>
                      <router-link v-if="game.teamA" v-html="teamName(game.teamA)"
                                   :to="{ name: 'schedules.teams.show', params: {uuid: $route.params.uuid, team: game.teamA.uuid }}"
                                   class="btn btn-sm btn-primary">
                      </router-link>
                      <span v-else class="badge badge-warning" style="font-size: 14px;"
                            v-html="teamName(game.teamA)">
                      </span>
                      <span v-if="game.teamA">
                        <i v-if="game.winner_team_id === game.teamA.id"
                           class="badge badge-success ml-2 p-2">{{ $t('game.w') }}</i>
                      </span>
                      <span v-if="game.teamB">
                          <i v-if="game.winner_team_id === game.teamB.id"
                             class="badge badge-danger ml-2 p-2">{{ $t('game.l') }}</i>
                      </span>
                    </span>

                    </td>
                    <td class="font-w600 font-size-sm text-center"><span>{{
                        game.home_team_score_total
                      }} : {{ game.away_team_score_total }}</span>
                    </td>
                    <td class="font-w600 font-size-sm">
                    <span>
                      <span v-if="game.teamA">
                        <i v-if="game.winner_team_id === game.teamA.id"
                           class="badge badge-danger mr-2 p-2">{{ $t('game.l') }}</i>
                      </span>
                      <span v-if="game.teamB">
                        <i v-if="game.winner_team_id === game.teamB.id"
                           class="badge badge-success mr-2 p-2">{{ $t('game.w') }}</i>
                      </span>
                      <router-link v-if="game.teamB" v-html="teamName(game.teamB)"
                                   :to="{ name: 'schedules.teams.show', params: {uuid: $route.params.uuid, team: game.teamB.uuid }}"
                                   class="btn btn-sm btn-primary">
                      </router-link>
                      <span v-else class="badge badge-warning" style="font-size: 14px;"
                            v-html="teamName(game.teamB)">
                      </span>
                    </span>
                    </td>
                    <td class="text-center">
                      <div class="btn-group">
                        <template  v-if="game.teamA && game.teamB">
                          <router-link
                            :to="{ name: 'schedules.games.show', params: {uuid: $route.params.uuid, game: game.uuid }}"
                            class="btn btn-sm btn-primary">
                            <i class="fa fa-pencil"></i>
                          </router-link>
                          <router-link
                            :to="{ name: 'schedules.games.showGallery', params: {uuid: $route.params.uuid, game: game.uuid }}"
                            class="btn btn-sm btn-primary">
                            <i class="fa fa-image"></i>
                          </router-link>
                          <pdf-game-download :game="game" :round="getNameOfRound(round.round)"
                                             :league="model"></pdf-game-download>
                          <xlsx-game-download :game="game" :round="getNameOfRound(round.round)"
                                              :league="model"></xlsx-game-download>
                        </template>

                        <button v-if="['NOT_PLAYED', 'SCHEDULED'].includes(game.status)" @click="swapGameTimeVenueBetweenGames(game)" :class="{'btn-success': swapGame?.uuid === game.uuid, 'btn-info': swapGame?.uuid !== game.uuid}" class="btn btn-sm"><i class="fa fa-arrows-v"></i></button>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </ntm-block>
      <ntm-block v-if="filteredGames && Object.keys(filteredGames).length > 0" :title="$tc('game.name', 2)">
        <div class="row">
          <div class="col-12 mb-3">
            <div class="d-flex flex-wrap w-100 mb-3" style="gap: 10px">
              <button @click="toggleLeagueFilterStatus('NOT_PLAYED')" type="button" class="btn"
                      :class="{'btn-primary': leagueGamesFilters.status.includes('NOT_PLAYED')}">
                {{ $tc('game.status.NOT_PLAYED', 2) }}
              </button>
              <button @click="toggleLeagueFilterStatus('SCHEDULED')" type="button" class="btn"
                      :class="{'btn-primary': leagueGamesFilters.status.includes('SCHEDULED')}">
                {{ $tc('game.status.SCHEDULED', 2) }}
              </button>
              <button @click="toggleLeagueFilterStatus('LIVE')" type="button" class="btn"
                      :class="{'btn-primary': leagueGamesFilters.status.includes('LIVE')}">{{
                  $tc('game.status.LIVE', 2)
                }}
              </button>
              <button @click="toggleLeagueFilterStatus('FINISHED')" type="button" class="btn"
                      :class="{'btn-primary': leagueGamesFilters.status.includes('FINISHED')}">
                {{ $tc('game.status.FINISHED', 2) }}
              </button>
              <button @click="toggleLeagueFilterStatus('ARCHIVED')" type="button" class="btn"
                      :class="{'btn-primary': leagueGamesFilters.status.includes('ARCHIVED')}">
                {{ $tc('game.status.ARCHIVED', 2) }}
              </button>

            </div>
            <div class="d-flex w-100 flex-wrap mb-3" style="gap: 10px">
              <div class="custom-control custom-checkbox custom-checkbox-rounded-circle custom-control-lg custom-control-danger mb-1">
                <input type="checkbox" v-model="leagueGamesFilters.isTerminated" id="is-terminated" class="custom-control-input">
                <label class="custom-control-label" for="is-terminated" v-text="$t('formly.fields.isTerminated')"></label>
              </div>
            </div>
            <div class="d-flex w-100 flex-wrap mb-3" style="gap: 10px">
              <multiselect
                v-if="model.groups.length > 1"
                :multiple="true"
                style="max-width: 300px"
                track-by="uuid"
                class="flex-grow-1"
                :custom-label="(option) => option.name"
                placeholder="Select group"
                v-model="leagueGamesFilters.groups"
                :options="model.groups">
              </multiselect>
              <multiselect :multiple="true"
                           style="max-width: 300px"
                           track-by="uuid"
                           class="flex-grow-1"
                           :custom-label="(option) => option.name"
                           placeholder="Select team"
                           v-model="leagueGamesFilters.filterByATeams"
                           :options="allTeams">
              </multiselect>
              <multiselect :multiple="true"
                           style="max-width: 300px"
                           track-by="uuid"
                           class="flex-grow-1"
                           :custom-label="(option) => option.name"
                           placeholder="Select team"
                           v-model="leagueGamesFilters.filterByBTeams"
                           :options="allTeams">
              </multiselect>
            </div>
          </div>

          <div v-for="(round, roundName) in filteredGames" :key="roundName" class="game-round col-12">
            <h4 v-if="round.length">{{ $tc('round', 1) }} {{ roundName }}</h4>
            <LeGamesTable @scheduleGameModal="scheduleGameModal" @cancelScheduledGame="cancelScheduledGame" @swapGameTimeVenue="fetchData"
                          :groups="model.groups"
                          v-if="round.length" :games="round" :league="model">
            </LeGamesTable>
          </div>
        </div>
      </ntm-block>

      <ntm-block v-if="model.friendlyGames && Object.keys(model.friendlyGames).length > 0"
                 :title="$tc('game.friendly', 2)">
        <template slot="options">
          <button class="btn btn-sm btn-danger" @click="deleteAllFriendlyGames()">
            <i class="fa fa-fw fa-trash"></i></button>
        </template>
        <div class="row">
          <div v-for="(round, roundName) in model.friendlyGames" :key="roundName" class="game-round col-12">
            <h4>{{ $tc('round', 1) }} {{ roundName }}</h4>
            <div class="table-responsive">
              <LeGamesTable @scheduleGameModal="scheduleGameModal" @cancelScheduledGame="cancelScheduledGame" @swapGameTimeVenue="fetchData"
                            v-if="round.length" :round-name="roundName" :games="round" :league="model">
              </LeGamesTable>
            </div>
          </div>
        </div>
      </ntm-block>

      <ntm-block :title="$tc('action.name', 2)">
        <template slot="options">
          <button class="btn btn-sm btn-warning" @click="deleteAllGames()">
            {{ $t('schedule.delete-league-games') }}
          </button>
          <button type="button"
                  @click="deleteSchedule(model.uuid)"
                  class="btn btn-sm btn-danger">{{ $t('schedule.delete') }}
          </button>
        </template>
      </ntm-block>

      <ntm-modal @submit="updateSchedule()" size="lg" :title="$tc('schedule.edit')" ref="editSchedule">
        <template slot="content">
          <formly-form :form="editScheduleForm" :model="model" :fields="editScheduleFields"></formly-form>
        </template>
        <template slot="footer">
          <button class="btn btn-success push-15" type="submit">{{ $t('save') }}</button>
        </template>
      </ntm-modal>

      <ntm-modal size="sm" :title="$t('fantasy.create')" ref="fantasyCreate">
        <template slot="content">
          <div class="mb-3">
            <span class="badge badge-pill badge-info text-white mr-1"><i class="fa fa-users"></i> {{model.fantasyLeague?.fantasyRegistrationsCount}}</span>
            <span class="badge badge-pill badge-info text-white"><i class="fa fa-play"></i> {{model.fantasyLeague?.fantasyGameScoresCount}}</span>
          </div>

          <formly-form :form="fantasyCreateForm"
                       :model="fantasyCreateData"
                       :fields="fantasyCreateFields"></formly-form>
        </template>
        <template slot="footer">
          <button class="btn btn-success mr-2" @click="upsertFantasy()">{{model.fantasyLeague?.uuid ?  $t('save') : $t('fantasy.create')}}</button>
          <button v-if="model.fantasyLeague?.uuid" class="btn btn-danger push-15" @click="deleteFantasy()">{{$t('fantasy.delete')}}</button>
        </template>
      </ntm-modal>

      <ntm-modal size="sm" :title="$t('schedule.games.generate')" ref="generateSchedule">
        <template slot="content">
          <formly-form :form="generateForm"
                       :model="generateData"
                       :fields="generateFields"></formly-form>
        </template>
        <template slot="footer">
          <button class="btn btn-success push-15" @click="generate()">Generate</button>
        </template>
      </ntm-modal>
      <ntm-modal size="md" :title="$t('game.create')" ref="addGame">
        <template slot="content">
          <formly-form :form="addGameForm"
                       :model="addGameData"
                       :fields="addGameFields"></formly-form>
        </template>
        <template slot="footer">
          <button class="btn btn-success push-15" @click="addGame()">{{ $t('save') }}</button>
        </template>
      </ntm-modal>
    </page-content>
    <modal name="schedule-modal" :adaptive="true" height="auto">
      <form @submit.prevent="scheduleGameSubmit">
        <ntm-block  :title="scheduleGameTitle">
          <formly-form :form="scheduleGameForm" :model="scheduleGameModel" :fields="scheduleGameFields"></formly-form>
          <button type="submit" class="btn btn-success push-15" :disabled="scheduleGameLoading"><span
            v-if="!scheduleGameLoading">{{ $t('save') }}</span><i v-if="scheduleGameLoading"
                                                                  class="fa fa-cog fa-spin"></i></button>
        </ntm-block>
      </form>
    </modal>
  </div>
</template>
<script>
import seasonShowMixin from '@/mixins/season.show.mixin'
import ScheduleService from '../../services/schedule.service'
import _ from 'lodash'
import TeamService from '../../services/team.service'
import generateScheduleFormDefinition from './formDefinitions/generateScheduleFormDefinition'
import addGameScheduleFormDefinition from './formDefinitions/addGameScheduleFormDefinition'
import GameService from '../../services/game.service'
import PdfGameDownload from '../../components/shared/PdfGameDownload'
import XlsxGameDownload from '../../components/shared/XlsxGameDownload'
import scheduleFormDefinition from '@/views/schedules/games/formDefinitions/scheduleFormDefinition'
import { gameStatusColor } from '@/lib/status'
import AddTeamForm from '@/views/schedules/createTeam/AddTeamForm.vue'
import updateScheduleFormDefinition from '@/views/schedules/formDefinitions/updateScheduleFormDefinition'
import TeamPlayersImagesDownload from '@/components/shared/TeamPlayersImagesDownload.vue'
import LeGamesTable from '@/components/le/LeGamesTable.vue'
import Multiselect from 'vue-multiselect'
import { useTimeline } from '@/use/useTimeline'
import { useGameSwap } from '@/use/useGameSwap'
import fantasyCreateFormDefinition from '@/views/schedules/formDefinitions/fantasyCreateFormDefinition'

export default {
  mixins: [seasonShowMixin],
  components: {
    Multiselect,
    LeGamesTable,
    TeamPlayersImagesDownload,
    PdfGameDownload,
    XlsxGameDownload,
    AddTeamForm
  },
  data () {
    return {
      nameImagesLoading: false,
      scheduleGameLoading: false,
      scheduleGameModel: {},
      scheduleGameForm: {},
      scheduleGameFields: scheduleFormDefinition,
      addGameForm: {},
      addGameFields: addGameScheduleFormDefinition,
      addGameData: {
        round: 1,
        group: {},
        teamA: {},
        teamB: {}
      },
      editScheduleFields: updateScheduleFormDefinition,
      editScheduleForm: {},
      generateForm: {},
      generateFields: generateScheduleFormDefinition,
      generateData: {
        cycles: 1
      },
      fantasyCreateForm: {},
      fantasyCreateFields: fantasyCreateFormDefinition,
      fantasyCreateData: {},
      showColumns: [
        {
          name: 'name',
          prop: 'name'
        }
      ],
      model: {
        games: [],
        groups: [],
        medals: []
      },
      leagueGamesFilters: {
        isTerminated: false,
        status: [],
        groups: [],
        filterByATeams: [],
        filterByBTeams: []
      }
    }
  },
  computed: {
    filteredByATeamsUuid () {
      return this.leagueGamesFilters.filterByATeams.map((team) => team.uuid)
    },
    filteredByBTeamsUuid () {
      return this.leagueGamesFilters.filterByBTeams.map((team) => team.uuid)
    },
    filteredGroupsUuid () {
      return this.leagueGamesFilters.groups.map((team) => team.uuid)
    },
    filteredGames () {
      const games = {}

      Object.keys(this.model.games).forEach((round, r) => {
        games[round] = this.model.games[round].filter((game) => {
          if (this.leagueGamesFilters.isTerminated && !game.isTerminated) {
            return false
          }

          if (this.leagueGamesFilters.groups.length && !this.filteredGroupsUuid.includes(game.group.uuid)) {
            return false
          }

          if (this.leagueGamesFilters.status.length && !this.leagueGamesFilters.status.includes(game.status)) {
            return false
          }

          if (this.filteredByATeamsUuid.length && !this.filteredByATeamsUuid.includes(game.teamA?.uuid) && !this.filteredByATeamsUuid.includes(game.teamB?.uuid)) {
            return false
          }

          if (this.filteredByBTeamsUuid.length && !this.filteredByBTeamsUuid.includes(game.teamA?.uuid) && !this.filteredByBTeamsUuid.includes(game.teamB?.uuid)) {
            return false
          }

          if (this.filteredByATeamsUuid.length && this.filteredByBTeamsUuid.length) {
            return (this.filteredByATeamsUuid.includes(game.teamA?.uuid) && this.filteredByBTeamsUuid.includes(game.teamB?.uuid)) ||
              (this.filteredByATeamsUuid.includes(game.teamB?.uuid) && this.filteredByBTeamsUuid.includes(game.teamA?.uuid))
          }

          return true
        })
      })

      return games
    },
    config () {
      return this.$store.state.config
    },
    allTeams () {
      const teams = []
      this.model.groups.forEach((group) => {
        teams.push(...group.teams)
      })

      return teams
    },
    scheduleGameTitle () {
      const homeTeamName = this.scheduleGameModel?.teamA?.name
      const awayTeamName = this.scheduleGameModel?.teamB?.name
      return `${homeTeamName} --:-- ${awayTeamName}`
    }
  },
  beforeRouteEnter (to, from, next) {
    ScheduleService.show(to.params.uuid).then((response) => {
      next(vm => vm.setData(response.data))
    })
  },
  setup () {
    const { fetchCurrentTimeline } = useTimeline()
    const { swapGame, swapGameTimeAndVenue } = useGameSwap()

    return {
      fetchCurrentTimeline, swapGame, swapGameTimeAndVenue
    }
  },
  methods: {
    upsertFantasy () {
      ScheduleService.upsertFantasy(this.$route.params.uuid, this.fantasyCreateData).then(() => {
        this.$refs.fantasyCreate.close()
        this.fetchData()
      })
    },
    deleteFantasy () {
      ScheduleService.deleteFantasy(this.$route.params.uuid, this.fantasyCreateData).then(() => {
        this.$refs.fantasyCreate.close()
        this.fetchData()
      })
    },
    async swapGameTimeVenueBetweenGames (game) {
      const swapGame = { ...game, league: { name: this.model.name } }
      await this.swapGameTimeAndVenue(swapGame)
      this.fetchData()
    },
    formatDate (time) {
      if (!time) {
        return ''
      }

      const date = new Date(time.replace('T', ' ').replace(/-/g, '/'))

      return `${this.$d(date, 'numericDateTime')}`
    },
    toggleLeagueFilterStatus (status) {
      if (this.leagueGamesFilters.status.includes(status)) {
        this.$mixpanel.track('Schedule games filter', { type: 'STATUS_CHANGE', added: status })
        this.leagueGamesFilters.status = this.leagueGamesFilters.status.filter(arrayItem => arrayItem !== status)
      } else {
        this.$mixpanel.track('Schedule games filter', { type: 'STATUS_CHANGE', removed: status })
        this.leagueGamesFilters.status.push(status)
      }
    },
    groupByGameRound (playoffRound) {
      return playoffRound.games.reduce((group, game) => {
        const { round } = game
        group[round] = group[round] ?? []
        group[round].push(game)
        return group
      }, {})
    },
    updateSchedule () {
      ScheduleService.update(this.$route.params.uuid, this.model).then(() => {
        this.fetchData()
      }).finally(() => {
        this.$refs.editSchedule.close()
        this.$store.commit('loaded')
      })
    },
    getMedalClass (place) {
      switch (place) {
        case 1:
          return 'text-gold'
        case 2:
          return 'text-silver'
        case 3:
          return 'text-bronze'
      }
    },
    getNameOfRound (round) {
      switch (round) {
        case 8:
          return this.$t('playoff.round.8')
        case 4:
          return this.$t('playoff.round.4')
        case 2:
          return this.$t('playoff.round.2')
        case 1:
          return this.$t('playoff.round.1')
        default:
          return this.$t('playoff.round.bestOf', { round: round })
      }
    },

    setData (data) {
      this.model = data
      this.addGameFields[2].options = data.groups
      this.addGameData.group = data.groups[0]

      const venuesField = this.scheduleGameFields.find(f => f.key === 'venue')

      if (this.model.venues.length) {
        venuesField.options = this.model.venues
      } else {
        venuesField.options = null
        venuesField.optionsApi = 'venues'
      }

      if (!this.model.fantasyLeague) {
        this.fantasyCreateData = {
          weeklyGamePredictionLimit: this.config.fantasy.weeklyGamePredictionLimit,
          teamPredictionScoreMultiplier: this.config.fantasy.teamPredictionScoreMultiplier,
          teamPredictionScoreMinValue: this.config.fantasy.teamPredictionScoreMinValue,
          starterPlayersCount: this.config.fantasy.roster.starterPlayersCount,
          benchPlayersCount: this.config.fantasy.roster.benchPlayersCount,
          minPlayerValue: this.config.fantasy.roster.minPlayerValue
        }
      } else {
        this.fantasyCreateData = this.model.fantasyLeague
      }

      this.editScheduleFields.find(f => f.key === 'extendsSchedules').options = data.scheduleOptions?.filter(s => s.uuid !== this.model.uuid)

      if (this.config.ai) {
        if (!this.editScheduleFields.find(f => f.key === 'aiGamesNews')) {
          this.editScheduleFields.push({
            key: 'aiGamesNews',
            type: 'ntm-select',
            multiple: true,
            isString: true,
            options: [
              'ALL_GAMES',
              'CLOSE_GAMES',
              'IMPORTANT_GAMES'
            ],
            templateOptions: {
              label: 'aiGamesNews',
              translated: true
            }
          })
        }

        if (!this.editScheduleFields.find(f => f.key === 'aiLeaguePreviousWeekSummaryNews')) {
          this.editScheduleFields.push({
            key: 'aiLeaguePreviousWeekSummaryNews',
            type: 'ntm-select',
            isString: true,
            options: [
              'OFF',
              'EVERY_MONDAY',
              'EVERY_TUESDAY',
              'EVERY_WEDNESDAY',
              'EVERY_THURSDAY',
              'EVERY_FRIDAY',
              'EVERY_SATURDAY',
              'EVERY_SUNDAY'
            ],
            templateOptions: {
              translated: true,
              label: 'aiLeaguePreviousWeekSummaryNews'
            }
          })
        }

        if (!this.editScheduleFields.find(f => f.key === 'aiPlayerRecordsNews')) {
          this.editScheduleFields.push({
            key: 'aiPlayerRecordsNews',
            type: 'boolean',
            templateOptions: {
              label: 'aiPlayerRecordsNews'
            }
          })
        }
      }
    },
    fetchData () {
      ScheduleService.show(this.$route.params.uuid).then((response) => {
        this.setData(response.data)
      })
    },
    addGroup () {
      ScheduleService.addGroup(this.$route.params.uuid).then(() => {
        this.fetchData()
        this.$store.commit('loaded')
      })
    },
    deleteGroup (uuid) {
      ScheduleService.deleteGroup(this.$route.params.uuid, uuid).then(() => {
        this.fetchData()
        this.$store.commit('loaded')
      })
    },
    dragTeam (groupUuid, teamUuid) {
      ScheduleService.changeGroup(this.$route.params.uuid,
        teamUuid,
        { group: { uuid: groupUuid } }).then(() => {
        this.fetchData()
        this.$store.commit('loaded')
      })
    },
    deleteTeam (uuid) {
      TeamService.destroy(this.$route.params.uuid, uuid).then(() => {
        this.fetchData()
      })
    },
    orderedTeams: function (teams) {
      return _.orderBy(teams, 'name')
    },
    teamName (team) {
      if (!team) {
        return 'TBD'
      }

      if (team?.name) {
        return team.name
      }

      return this.$t('emptySlot')
    },
    generate () {
      ScheduleService.generate(this.$route.params.uuid, this.generateData).then(() => {
        this.$refs.generateSchedule.close()
        this.fetchData()
        this.$store.commit('loaded')
      }).catch(() => {
        this.$refs.generateSchedule.close()
      })
    },
    regenerate () {
    },
    addGame () {
      ScheduleService.addGame(this.$route.params.uuid, this.addGameData).then(() => {
        this.$refs.addGame.close()
        this.fetchData()
        this.addGameData.teamA = {}
        this.addGameData.teamB = {}
        this.$store.commit('loaded')
        this.addGameForm.$errors = {}
      }).catch((err) => {
        this.addGameForm.$errors = err.response.data.errors
        this.$store.commit('loaded')
      })
    },
    deleteGame (uuid) {
      GameService.destroy(this.$route.params.uuid, uuid).then(() => {
        this.fetchData()
      })
    },
    deleteAllGames () {
      return ScheduleService.deleteAllGames(this.$route.params.uuid).then(() => {
        this.fetchData()
        this.$store.commit('loaded')
      })
    },
    deleteAllFriendlyGames () {
      return ScheduleService.deleteAllGames(this.$route.params.uuid, 'FRIENDLY').then(() => {
        this.fetchData()
        this.$store.commit('loaded')
      })
    },
    gameStatusColor,
    updateAddGameTeams () {
      this.addGameData.teamA = {}
      this.addGameData.teamB = {}
      if (this.addGameData.isFriendlyGame) {
        this.addGameData.group = null
        this.addGameFields[2].disabled = true
        this.addGameFields[3].options = this.allTeams
        this.addGameFields[4].options = this.allTeams
      } else {
        this.addGameFields[2].disabled = false
        if (this.addGameData.group) {
          this.addGameFields[3].disabled = false
          this.addGameFields[4].disabled = false
          this.addGameFields[3].options = this.addGameData.group.teams
          this.addGameFields[4].options = this.addGameData.group.teams
        } else {
          this.addGameFields[3].options = []
          this.addGameFields[3].disabled = true
          this.addGameFields[4].options = []
          this.addGameFields[4].disabled = true
        }
      }
    },
    scheduleGameModal (game) {
      if (!game.venue && this.model.venues.length === 1) {
        game.venue = this.model.venues[0]
      }

      this.scheduleGameModel = game

      if (!this.scheduleGameModel.time) {
        const d = new Date()
        const date = d.toISOString().split('T')[0]
        const time = d.toTimeString().split(' ')[0].split(':').slice(0, 2).join(':')

        this.scheduleGameModel.time = `${date} ${time}`
      }

      this.$modal.show('schedule-modal')
    },
    cancelScheduledGame (game) {
      GameService.cancelScheduledGame(this.$route.params.uuid, game.uuid).then((res) => {
        game.time = res.data.time
        game.venue = res.data.venue
        game.code = res.data.code
        game.status = res.data.status

        this.fetchCurrentTimeline()
      }).finally(() => {
        this.$mixpanel.track('Cancel scheduled game on schedule page')
      })
    },
    scheduleGameSubmit () {
      this.scheduleGameLoading = true
      GameService.scheduleGame(this.$route.params.uuid, this.scheduleGameModel.uuid, {
        time: this.scheduleGameModel.time,
        venue: this.scheduleGameModel.venue,
        info: this.scheduleGameModel.info
      }).then((res) => {
        this.fetchCurrentTimeline()
        this.scheduleGameModel.time = res.data.time
        this.scheduleGameModel.venue = res.data.venue
        this.scheduleGameModel.code = res.data.code
        this.scheduleGameModel.status = res.data.status
        this.$modal.hide('schedule-modal')
      }).catch((err) => {
        this.scheduleGameForm.$errors = err.response.data.errors
      }).finally(() => {
        this.scheduleGameLoading = false
        this.$mixpanel.track('Schedule game on schedule page')
      })
    },
    deleteSchedule (id) {
      ScheduleService.delete(id).then(() => {
        this.$router.push({ name: 'schedules.index' })
      })
    },
    forceUpdate () {
      this.$forceUpdate()
    }
  },
  watch: {
    'leagueGamesFilters.filterByATeams': {
      handler: function () {
        this.$mixpanel.track('Schedule games filter', { type: 'TEAM_CHANGE' })
      },
      deep: true
    },
    'leagueGamesFilters.filterByBTeams': {
      handler: function () {
        this.$mixpanel.track('Schedule games filter', { type: 'TEAM_CHANGE' })
      },
      deep: true
    },
    'addGameData.group': 'updateAddGameTeams',
    'addGameData.isFriendlyGame': 'updateAddGameTeams'
  }
}
</script>

<style type="scss">
.short-block {
  max-width: 800px;
  margin: 0 auto 20px auto;
}

.game-round {
  margin-bottom: 15px;
}

.groups .block-content .row {
  min-height: 50px;
  padding-bottom: 30px;
}

.groups .team-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid #eeeeee;
}

.groups .team span {
  //line-height: 30px;
}

.groups .team .btn {
  float: right;
  margin-left: 5px;
}

.team-right-side {
  display: flex;
}

.team-right-side-item {
  display: flex;
  align-items: center;
}
</style>
