<template>
  <div class="d-inline-block">
    <button v-if="game.embedVideo?.source_type !== 'YOUTUBE'" :disabled="disableButtons || !['SCHEDULED', 'LIVE'].includes(game.status)" class="btn btn-sm btn-warning" @click="makeYTGameEvent()"><i v-if="!loading" class="fa fa-youtube"></i> <i v-if="loading" class="fa fa-cog fa-spin"></i></button>
    <button v-if="game.embedVideo?.source_type === 'YOUTUBE'" :disabled="disableButtons" class="btn btn-sm btn-danger" @click="deleteYTGameEvent()"><i v-if="!loading" class="fa fa-youtube-play"></i> <i v-if="loading" class="fa fa-cog fa-spin"></i></button>
  </div>
</template>

<script>
import { useExternalLinks } from '@/use/useExternalLinks'
import GameService from '@/services/game.service'

export default {
  props: {
    schedule: Object,
    game: Object,
    disableButtons: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false
    }
  },
  setup () {
    const { getGameWebsiteUrl } = useExternalLinks()

    return {
      getGameWebsiteUrl
    }
  },
  watch: {
    size: 'changeSize'
  },
  methods: {
    deleteYTGameEvent () {
      this.loading = true
      GameService.deleteYtEvent(this.schedule.uuid, this.game.uuid).then(() => {
        this.emitChange()
      }).catch((error) => {
        if (error.response.data.message === 'connected-account-missing' && error.response.status === 422) {
          this.$router.push({ name: 'connected-account.index' })
        }
      }).finally(() => {
        this.loading = false
      })
    },
    makeYTGameEvent () {
      this.loading = true
      GameService.createYtEvent(this.schedule.uuid, this.game.uuid).then(() => {
        this.emitChange()
      }).catch((error) => {
        if (error.response.data.message === 'connected-account-missing' && error.response.status === 422) {
          this.$router.push({ name: 'connected-account.index' })
        }
      }).finally(() => {
        this.loading = false
      })
    },
    emitChange () {
      this.$emit('changed')
    }
  }
}
</script>
<style>
 .qr-button {
   display: block;
   border: 0;
   background: none;
   cursor: pointer;
 }
</style>
