<template>
  <div class="d-inline-block ">
    <button class="btn btn-sm btn-info" @click="openEditModal()"><i class="fa fa-file-excel-o"></i></button>
    <modal :name="`export-games-csv`" :adaptive="true" height="auto">
      <form class="position-relative" @submit.prevent="exportCsv()">
        <le-loading v-if="isOptionsLoading"></le-loading>
        <ntm-block>
          <formly-form :form="form" :model="model" :fields="fields"></formly-form>
          <div class="d-flex mb-2 justify-content-center">
            <button class="btn btn-success push-15 mr-2" :disabled="loading"><span
              v-if="!loading">Export </span><i v-if="loading"
                                                        class="fa fa-cog fa-spin"></i></button>
          </div>
        </ntm-block>
      </form>
    </modal>
  </div>
</template>

<script>
import DashboardService from '@/services/dashboard.service'
import LeLoading from '@/components/ntm/LeLoading.vue'

export default {
  components: { LeLoading },

  data () {
    return {
      isOptionsLoading: false,
      leagues: [],
      model: {
        code: ''
      },
      form: {},
      fields: [
        {
          key: 'leagues',
          type: 'ntm-select',
          multiple: true,
          options: [],
          templateOptions: {
            attrs: {
              rows: 5
            },
            label: 'league'
          }
        }
      ],
      deleteLoading: false,
      loading: false
    }
  },
  watch: {
    size: 'changeSize'
  },
  methods: {
    exportCsv () {
      this.loading = true
      DashboardService.exportScheduledGamesCsv(this.model).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]))
        const fileLink = document.createElement('a')

        const date = new Date()

        const day = date.getDate()
        const month = date.getMonth() + 1
        const year = date.getFullYear()

        let filename = `${year}-${month}-${day}-scheduled-games.csv`

        if (this.model.leagues?.length) {
          this.model.leagues?.forEach(league => {
            filename = `${league.name}-${filename}`
          })
        }

        fileLink.href = fileURL
        fileLink.setAttribute('download', filename)
        document.body.appendChild(fileLink)
        fileLink.click()
      }).finally(() => {
        this.loading = false
      })
    },
    async fetchLeagues () {
      this.isOptionsLoading = true
      const response = await DashboardService.getLeagues()

      console.log(response.data)
      this.leagues = response.data.leagues
      const leaguesField = this.fields.find(field => field.key === 'leagues')
      leaguesField.options = this.leagues

      this.isOptionsLoading = false
    },
    async openEditModal () {
      this.fetchLeagues()
      this.$modal.show('export-games-csv')
    }
  }
}
</script>
<style>
 .qr-button {
   display: block;
   border: 0;
   background: none;
   cursor: pointer;
 }
</style>
